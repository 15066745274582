import { useState, useEffect } from "react";
import { pb } from "../../../shared/pb.js";

const useApi = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const routingData = await pb.collection("routing")
        .getFullList({
          sort: "-created",
          $autoCancel: false,
        });

      const routingTimeData = await pb.collection("routing_time")
        .getFullList({
          sort: "-created",
          $autoCancel: false,
        });

      const mergedData = mergeDataWithTime(routingData, routingTimeData);
      setData(mergedData);
    };

    fetchData();
  }, []);

  const mergeDataWithTime = (routingData, routingTimeData) => {
    return routingData.map(item => {
      const matchingTimeItem = routingTimeData.find(timeItem => timeItem.tag === item.tag);
  
      return {
        ...item,
        time: matchingTimeItem ? convertTo24HourFormat(matchingTimeItem.start_time, matchingTimeItem.end_time) : null,
      };
    });
  };
  
  const convertTo24HourFormat = (startTime, endTime) => {
    // Преобразование времени в 24-часовой формат без секунд
    const options = { hour12: false, hour: 'numeric', minute: 'numeric' };
  
    const start24Hour = new Date(`2022-01-01 ${startTime}`).toLocaleTimeString('en-US', options);
    const end24Hour = new Date(`2022-01-01 ${endTime}`).toLocaleTimeString('en-US', options);
  
    return `${start24Hour} - ${end24Hour}`;
  };

  return { data, setData };
};

export default useApi;
