import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { Stack, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import CreateAlert from "./components/CreateAlert";
import useApi from "../../api/useApi";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { pb } from "../../../../shared/pb.js";
import LoadingButton from "@mui/lab/LoadingButton";

export default function CreateDarkstore() {
  const [tag, setTag] = React.useState(null);
  const [name, setName] = React.useState("");
  const [koordinator, setKoordinator] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { data } = useApi();

  const FilterTag = [
    ...new Set(
      data.map((person) => {
        return person.tag.toUpperCase();
      })
    ),
  ];

  const FilterKoordinator = [
    ...new Set(
      data.map((person) => {
        return person.koordinator.slice(12);
      })
    ),
  ];

  function createRows() {
    pb.collection("routing")
      .create({
        tag: tag,
        name: name.toUpperCase(),
        koordinator: "Координаторы" + koordinator,
      })
      .then();

    setKoordinator(null);
    setTag(null);
    setName("");
    setOpen(true);
  }


  const handleupload = async (e) => {
    const file = e.target.files[0];
  
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
  
        const response = await fetch("https://proxy.smkt.pro/import_file", {
          method: "POST",
          body: formData,
        });
  
        if (response.ok) {
          console.log("Файл успешно загружен");
        } else {
          console.error("Ошибка загрузки файла");
        }
      } catch (error) {
        console.error("Произошла ошибка:", error);
      }
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        bgcolor: "background.paper",
        p: 2,
        borderRadius: "8px",
        minWidth: 360,
      }}
    >
      <Typography sx={{ color: "text.secondary" }} variant="h5" gutterBottom>
        Добавить DarkStore
      </Typography>
      <Autocomplete
        disablePortal
        sx={{ width: "100%" }}
        id="combo-box-demo"
        options={FilterTag}
        getOptionLabel={(option) => `${option}`}
        value={tag}
        onChange={(event, newInputValue, reason) => {
          setTag(newInputValue);
        }}
        onInputChange={(e, value) => setTag(value)}
        renderInput={(params) => (
          <TextField label="ТЕГ" variant="outlined" {...params} />
        )}
      />
      <TextField
        autoComplete="off"
        onChange={(e) => setName(e.target.value)}
        id="outlined-basic"
        label="Название DarkStore"
        variant="outlined"
        value={name.toUpperCase()}
        sx={{ width: "100%" }}
      />
      <Autocomplete
        disablePortal
        sx={{ width: "100%" }}
        id="combo-box-demo"
        options={FilterKoordinator}
        getOptionLabel={(option) => `${option}`}
        value={koordinator}
        onChange={(event, newInputValue, reason) => {
          setKoordinator(newInputValue);
        }}
        onInputChange={(e, value) => setKoordinator(value)}
        renderInput={(params) => (
          <TextField label="Координатор" variant="outlined" {...params} />
        )}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >


<Box>
  <input
    id="upload-button"
    accept=".xls, .xlsx"
    type="file"
    style={{ display: "none" }}
    value=""
    onChange={handleupload}
  />
  <label htmlFor="upload-button">
    <LoadingButton
      sx={{
        width: 70,
        border: "solid 2.5px rgb(0,150,136)",
      }}
      component="span"
    >
      <FileDownloadIcon/>
    </LoadingButton>
  </label>
</Box>
      <Button
        sx={{ width: 270, border: "solid 2.5px rgb(0,150,136)" }}
        onClick={createRows}
        endIcon={<AddIcon />}
      >
        Добавить
      </Button>

      </Stack>
      <CreateAlert setOpen={setOpen} open={open}></CreateAlert>
    </Stack>
  );
}
