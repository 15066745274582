import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logoWhite from "../images/logoWhite.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import RouteIcon from "@mui/icons-material/Route";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import TelegramIcon from "@mui/icons-material/Telegram";
import PeopleIcon from "@mui/icons-material/People";
import ContentPasteTwoToneIcon from "@mui/icons-material/ContentPasteTwoTone";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import ExtensionTwoToneIcon from "@mui/icons-material/ExtensionTwoTone";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SatelliteAltOutlinedIcon from "@mui/icons-material/SatelliteAltOutlined";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';

function ResponsiveAppBar({ setFirst, mode, setMode, theme }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleToggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    localStorage.setItem("mode", newMode);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 10, display: { xs: "none", md: "flex" } }}>
            <Box>
              <TemporaryDrawer mode={mode} />
            </Box>
            <Box
              component="img"
              src={logoWhite}
              alt="img"
              sx={{
                display: { xs: "none", md: "flex" },
                minWidth: "110px",
                ml: 5,
              }}
            ></Box>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box>
              <IconButton onClick={handleToggleMode} color="inherit">
                {theme.palette.mode === "dark" ? (
                  <WbSunnyIcon />
                ) : (
                  <Brightness3Icon />
                )}
              </IconButton>{" "}
              <IconButton
                size="large"
                sx={{
                  color: "White",
                }}
                onClick={() => {
                  setFirst((prev) => !prev);
                  localStorage.removeItem("token");
                }}
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function TemporaryDrawer({ mode }) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_HELP_EDIT_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
        localStorage.getItem("role") ===
          process.env.REACT_APP_HELP_VIEW_ROLE ? (
          <Link to="/v1/routing" className="Link">
            <ListItem key="МАРШРУТИЗАЦИЯ" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <RouteIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="МАРШРУТИЗАЦИЯ"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ? (
          <Link to="/v1/lzk" className="Link">
            <ListItem key="ЛЗК" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Diversity3Icon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="ЛЗК"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_DELETE_ROLE ? (
          <Link to="/v1/delete" className="Link">
            <ListItem key="Удаление уволенных" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PersonRemoveIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Удаление уволенных"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_SBL_ROLE ? (
          <Link to="/v1/sbltgbot" className="Link">
            <ListItem key="СБЛ TG_bot" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <TelegramIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="СБЛ TG_bot"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_GROUP_SV_ROLE ? (
          <Link to="/v1/groups" className="Link">
            <ListItem key="Группы СВ" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Группы СВ"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_HELP_VIEW_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
        localStorage.getItem("role") ===
          process.env.REACT_APP_HELP_EDIT_ROLE ? (
          <Link to="/v1/forma" className="Link">
            <ListItem key="ЭДО связь" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ContentPasteTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="ЭДО связь"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ? (
          <Link to="/v1/edo" className="Link">
            <ListItem key="ЭДО" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ContentPasteRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="ЭДО"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_HELP_VIEW_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
        localStorage.getItem("role") ===
          process.env.REACT_APP_HELP_EDIT_ROLE ? (
          <Link to="/v1/formaPRO" className="Link">
            <ListItem key="УЗНАЙ ФОРМА" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ExtensionTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="УЗНАЙ ФОРМА"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ? (
          <Link to="/v1/uznaiPRO" className="Link">
            <ListItem key="УЗНАЙ СМС" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ExtensionOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="УЗНАЙ СМС"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ? (
          <Link to="/v1/statistics" className="Link">
            <ListItem key="Бот статистики" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ContentCopyOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Бот статистики"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}

        {/* {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_HELP_VIEW_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
        localStorage.getItem("role") ===
          process.env.REACT_APP_HELP_EDIT_ROLE||
          localStorage.getItem("role") === process.env.REACT_APP_TEST ? (
          <Link to="/v1/admin" className="Link">
            <ListItem key="Супер админ панель" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SatelliteAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Супер админ панель"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )} */}

        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_RYK_LYAM_ROLE ? (
          <Link to="/v1/tgbot" className="Link">
            <ListItem key="TG_BOT" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SendOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="TG_BOT"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}
        {/* {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_LZK_L1_ROLE ? (
          <Link to="/v1/SBLforma" className="Link">
            <ListItem key="Узнай форма ЛЗК" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CheckCircleTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Узнай форма ЛЗК"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )} */}

        {/* {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ? (
          <Link to="/v1/SBLuznaiPRO" className="Link">
            <ListItem key="Узнай смс ЛЗК" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Узнай смс ЛЗК"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )} */}
          {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_CLOSING_CHATS ? (
          <Link to="/v1/closingChats" className="Link">
            <ListItem key="Автозакрытие чатов" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AutoDeleteIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: mode === "light" ? "black" : "white",
                  }}
                  primary="Автозакрытие чатов"
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <></>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <DehazeIcon
              sx={{
                color: "white",
              }}
            />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default ResponsiveAppBar;
