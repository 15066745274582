import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import DeleteModal from "./DeleteModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DeleteBotChats from "./DeleteBotChats";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Grid } from "@mui/material";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";

import Stack from "@mui/material/Stack";

export default function DeleteBot({ pb, setFirst }) {
  const [base, setbase] = useState("deleteBot");
  const [baseDelete, setbaseDelete] = useState("deleteBotChat");
  const [sort, setSort] = useState("deleteBot");
  const [buttonText, setButtonText] = useState("чатов цфз");
  const [realtime, setRealtime] = useState();

  const handleChange = (event, newSort) => {
    // eslint-disable-next-line default-case
    switch (newSort) {
      case "deleteBot":
        setbase("deleteBot");
        setbaseDelete("deleteBotChat");
        setButtonText("чатов ЦФЗ");
        setSort(newSort);
        break;
      case "deleteBot_full":
        setbase("deleteBot_full");
        setbaseDelete("deleteBotChat_full");
        setButtonText("всех чатов");
        setSort(newSort);
        break;
      case "deleteBotOKK":
        setbase("deleteBotOKK");
        setbaseDelete("deleteBotChatOKK");
        setButtonText("чатов ОКК");
        setSort(newSort);
        break;
      case "deleteBotMM":
        setbase("deleteBotMM");
        setbaseDelete("deleteBotChatMM");
        setButtonText("чатов ММ");
        setSort(newSort);
        break;
      case "chatLid":
          setbase("deleteBotLid");
          setbaseDelete("deleteBotChatLid");
          setButtonText("чаты тимлидов");
          setSort(newSort);
          break;
      case "ceniorLid":
          setbase("deleteBotCeniorLid");
          setbaseDelete("deleteBotChatCeniorLid");
          setButtonText("чаты старших тимлидов");
          setSort(newSort);
          break;
    }
  };

  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    pb.collection(base)
      .getFullList({
        sort: "-created",
      })

      .then((ait) => setData(ait));
  }, [base, realtime]);

  React.useEffect(() => {
    pb.collection(base).subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, [base]);

  const getRowId = (data) => data.tgId;

  const columns = [
    {
      editable: false,
      sortable: false,

      renderCell: () => <PersonIcon sx={{ color: "text.secondary" }} />,
    },
    {
      field: "fullName",
      renderHeader: (params) => (
        <Grid sx={{ width: 250 }} container justifyContent="center">
          ФИО
        </Grid>
      ),
      width: 475,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Grid container sx={{ pl: 5 }}>
          {params.value}
        </Grid>
      ),
    },

    {
      field: "tgId",
      renderHeader: (params) => (
        <Grid sx={{ width: 480 }} container justifyContent="center">
          Telegram ID
        </Grid>
      ),
      renderCell: (params) => (
        <Grid container justifyContent="center">
          {params.value}
        </Grid>
      ),

      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box sx={{ width: 900 }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            width: 900,
            bgcolor: "background.paper",
            color: "white",

            borderRadius: 2,
          }}
        >
          <BottomNavigationAction
            label="Удалить операторов"
            icon={<PersonRemoveIcon sx={{}} />}
            sx={{
              ml: 12,
              minWidth: "330px",
              maxWidth: "330px",
            }}
          />

          <BottomNavigationAction
            sx={{
              mr: 12,
              minWidth: "330px",
              maxWidth: "330px",
            }}
            label="Добавить чаты"
            icon={<MapsUgcIcon />}
          />
        </BottomNavigation>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Box>
            <Tabs
              centered
              sx={{
                width: 900,
                bgcolor: "background.paper",
                mt: 1,
                borderRadius: "8px 8px 0px 0px ",
              }}
              value={sort}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              scrollButtons
              variant = "scrollable"
            >
              <Tab
                sx={{ width: 200 }}
                value="deleteBot"
                label=<b>Чаты ЦФЗ</b>
              />
              <Tab
                sx={{ width: 200 }}
                value="deleteBot_full"
                label=<b>Все чаты</b>
              />

              <Tab
                sx={{ width: 200 }}
                value="deleteBotOKK"
                label=<b>Чаты ОКК</b>
              />
              <Tab
                sx={{ width: 200 }}
                value="deleteBotMM"
                label=<b>Чаты ММ</b>
              />
                <Tab
                sx={{ width: 200 }}
                value="chatLid"
                label=<b>Чаты тимлидов</b>
              />
                <Tab
                sx={{ width: 200 }}
                value="ceniorLid"
                label=<b>Чаты старших тимлидов</b>
              />
            </Tabs>{" "}
          </Box>
          <Box
            sx={{
              height: "68vh",
              width: 900,
              bgcolor: "background.paper",
            }}
          >
            {value === 0 && (
              <>
                <DataGrid
                  sx={{
                    borderRadius: "0px ",
                    border: "0px",
                    ".MuiDataGrid-cell:focus": { outline: 0 },
                    ".MuiDataGrid-cell:focus-within": { outline: 0 },
                    ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },
                    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                      {
                        display: "none",
                      },
                    "& .MuiDataGrid-columnHeader:nth-of-type(1) .MuiDataGrid-columnSeparator":
                      {
                        display: "none",
                      },

                    "& .MuiDataGrid-iconSeparator": {
                      display: "none",
                    },
                  }}
                  rows={data}
                  columns={columns}
                  getRowId={getRowId}
                  hideFooter={true}
                  stickyHeader
                  disable
                  disableRowSelectionOnClick
                  disableColumnSelector
                  localeText={
                    ruRU.components.MuiDataGrid.defaultProps.localeText
                  }
                />
                <Divider></Divider>
                <Box
                  sx={{
                    width: 900,
                    bgcolor: "background.paper",
                    p: 1,
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <DeleteModal
                    pb={pb}
                    setData={setData}
                    base={base}
                    baseDelete={baseDelete}
                    buttonText={buttonText}
                  />
                </Box>
              </>
            )}
            {value === 1 && (
              <DeleteBotChats
                pb={pb}
                setFirst={setFirst}
                base={baseDelete}
              ></DeleteBotChats>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
