import React from "react";
import PocketBase from "pocketbase";
import { Box, Container } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Authorization from "./Authorization";
import DeleteBot from "./DeleteBot.jsx";
import Lzk from "./Lzk.jsx";
import SBL_tg_bot from "./SBL_tg_bot.jsx";
import EDOtable from "./EDOtable.jsx";
import GroupsSv from "./GroupsSV.jsx";
import Forma from "./Forma.jsx";
import Header from "./Header";
import Tg_bot_ruk_lyam from "./Tg_bot_ruk_lyam.jsx";
import { DateTime } from "luxon";
import СoordinatorRoutingPage from "../pages/СoordinatorRouting/СoordinatorRoutingPage.jsx";
import UznaiPRO_forma from "./UznaiPRO_forma";
import UznaiPRO_table from "./UznaiPRO_table";
// import Task_copywriter from "./Task_copywriter";
// import Adminpanel from "./Adminpanel";
// import SBL_forma from "./SBL_forma";
// import SBL_table from "./SBL_table";
import Closing_chats from "./Closing_chats.jsx"
import AuthTgBot from "./AuthTgBot.jsx"


export default function Marshrut({ setMode, mode, theme }) {
  const pb = new PocketBase(process.env.REACT_APP_URL_BASE);
  const [first, setFirst] = React.useState(false);

  const tableBlock =
    localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
    localStorage.getItem("role") === process.env.REACT_APP_HELP_EDIT_ROLE
      ? true
      : false;

  React.useEffect(() => {}, [first]);

  if (localStorage.getItem("token")) {
    return (
      <BrowserRouter>
        <Header
          setMode={setMode}
          mode={mode}
          setFirst={setFirst}
          theme={theme}
        ></Header>{" "}
        <Container maxWidth="xl">
          <Routes>
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_EDIT_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_VIEW_ROLE ? (
              <Route
                path="/v1/routing"
                element={
                  <Box
                    sx={{
                      marginTop: 2,
                    }}
                  >
                    <СoordinatorRoutingPage
                      pb={pb}
                      setFirst={setFirst}
                      tableBlock={tableBlock}
                    ></СoordinatorRoutingPage>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_DELETE_ROLE ? (
              <Route
                path="/v1/delete"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <DeleteBot pb={pb} setFirst={setFirst}></DeleteBot>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ? (
              <Route
                path="/v1/lzk"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <Lzk DateTime={DateTime} pb={pb} setFirst={setFirst}></Lzk>{" "}
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_SBL_ROLE ? (
              <Route
                path="/v1/sbltgbot"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <SBL_tg_bot
                      DateTime={DateTime}
                      pb={pb}
                      setFirst={setFirst}
                      tableBlock={tableBlock}
                    ></SBL_tg_bot>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ? (
              <Route
                path="/v1/edo"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <EDOtable
                      pb={pb}
                      setFirst={setFirst}
                      tableBlock={tableBlock}
                      DateTime={DateTime}
                    ></EDOtable>{" "}
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_GROUP_SV_ROLE ? (
              <Route
                path="/v1/groups"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <GroupsSv pb={pb} setFirst={setFirst}></GroupsSv>{" "}
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_VIEW_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_EDIT_ROLE ? (
              <Route
                path="/v1/forma"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <Forma pb={pb} setFirst={setFirst}></Forma>{" "}
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_RYK_LYAM_ROLE ? (
              <Route
                path="/v1/tgbot"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <Tg_bot_ruk_lyam
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></Tg_bot_ruk_lyam>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_VIEW_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_EDIT_ROLE ? (
              <Route
                path="/v1/formaPRO"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <UznaiPRO_forma
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></UznaiPRO_forma>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ? (
              <Route
                path="/v1/uznaiPRO"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <UznaiPRO_table
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></UznaiPRO_table>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ? (
              <Route
                path="/v1/statistics"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <AuthTgBot
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></AuthTgBot>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
            {/* {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_VIEW_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_EDIT_ROLE||
              localStorage.getItem("role") === process.env.REACT_APP_TEST ? (
              <Route
                path="/v1/admin"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <Adminpanel
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></Adminpanel>
                  </Box>
                }
              />
            ) : (
              <></>
            )} */}
            {/* {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_LZK_L1_ROLE ? (
              <Route
                path="/v1/SBLforma"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <SBL_forma
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></SBL_forma>
                  </Box>
                }
              />
            ) : (
              <></>
            )} */}

            {/* {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ? (
              <Route
                path="/v1/SBLuznaiPRO"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <SBL_table
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></SBL_table>
                  </Box>
                }
              />
            ) : (
              <></>
            )} */}

            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") === process.env.REACT_APP_CLOSING_CHATS ? (
              <Route
                path="/v1/closingChats"
                element={
                  <Box sx={{ marginTop: 2 }}>
                    <Closing_chats
                      pb={pb}
                      setFirst={setFirst}
                      DateTime={DateTime}
                    ></Closing_chats>
                  </Box>
                }
              />
            ) : (
              <></>
            )}

            {localStorage.getItem("role") ===
              process.env.REACT_APP_ADMIN_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_EDIT_ROLE ||
            localStorage.getItem("role") ===
              process.env.REACT_APP_HELP_VIEW_ROLE ? (
              <Route path="*" element={<Navigate to="/v1/routing" replace />} />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
            process.env.REACT_APP_DELETE_ROLE ? (
              <Route path="*" element={<Navigate to="/v1/delete" replace />} />
            ) : (
              <></>
            )}
                        {localStorage.getItem("role") ===
            process.env.REACT_APP_TEST ? (
              <Route path="*" element={<Navigate to="/v1/admin" replace />} />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") === process.env.REACT_APP_LZK_ROLE ? (
              <Route path="*" element={<Navigate to="/v1/lzk" replace />} />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") === process.env.REACT_APP_SBL_ROLE ? (
              <Route
                path="*"
                element={<Navigate to="/v1/sbltgbot" replace />}
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") === process.env.REACT_APP_EDO_ROLE ? (
              <Route path="*" element={<Navigate to="/v1/edo" replace />} />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
            process.env.REACT_APP_GROUP_SV_ROLE ? (
              <Route path="*" element={<Navigate to="/v1/groups" replace />} />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
            process.env.REACT_APP_RYK_LYAM_ROLE ? (
              <Route path="*" element={<Navigate to="/v1/tgbot" replace />} />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") === process.env.REACT_APP_SMM ? (
              <Route
                path="*"
                element={<Navigate to="/v1/tgbotsmm" replace />}
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") === process.env.REACT_APP_CLOSING_CHATS ? (
              <Route
                path="*"
                element={<Navigate to="/v1/closingChats" replace />}
              />
            ) : (
              <></>
            )}
            {localStorage.getItem("role") ===
            process.env.REACT_APP_LZK_L1_ROLE ? (
              <Route
                path="*"
                element={<Navigate to="/v1/SBLforma" replace />}
              />
            ) : (
              <></>
            )}
          </Routes>
        </Container>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/v1/auth"
            element={
              <Authorization
                pb={pb}
                setFirst={setFirst}
                setMode={setMode}
                mode={mode}
                theme={theme}
              />
            }
          />
          <Route path="*" element={<Navigate to="/v1/auth" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
