import * as React from "react";
import { Box, Stack } from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EDOblack from "./EDOblack";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import rocket from "../images/rocket.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Grid } from "@mui/material";

export default function DenseTable({ setFirst, pb, DateTime }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [text, setText] = React.useState("");
  const [param, setParam] = React.useState("");
  const [datetimes, setDatetimes] = React.useState([]);
  const [realtime, setRealtime] = React.useState([]);
  const [click_button, setClick_button] = React.useState(0);
  const [open_alert_error, setOpen_alert_error] = React.useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  let time = new Date();
  let year = time.getUTCFullYear();
  let month = String(time.getUTCMonth() + 1).padStart(2, "0");
  let day = String(time.getUTCDate()).padStart(2, "0");
  let dateTime = `${year}-${month}-${day}`;

  const status = [
    "Готов к приёмке",
    "Отменил поставщик",
    "Отменил приёмщик",
    "Заказа нет",
    "Другая дата",
    "УР неверный",
    "Свой текст",
    "Без письма",
  ];

  const handleClick = () => {
    setClick_button((prevValue) => (prevValue === 1 ? 0 : 1));
  };

  const handleClose_alert_error = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen_alert_error(false);
  };

  const filteredData = datetimes.filter((row) => {
    if (click_button === 0) {
      return row.sending === "";
    } else if (click_button === 1) {
      return row.sending !== "";
    }
    return false; // Return false for other cases
  });

  React.useEffect(() => {
    pb.collection("edo")
      .getFullList({
        sort: "-created",
        filter: `created >= "${dateTime}"`,
      })
      .then((ait) => {
        const formattedData = ait.map((item) => {
          const formattedDate = DateTime.fromFormat(
            item.created,
            "yyyy-MM-dd HH:mm:ss.SSS'Z'"
          )
            .setLocale("ru")
            .plus({ hours: 3 })
            .toFormat("d.MM.yy HH:mm");
          return { ...item, created: formattedDate };
        });

        return setDatetimes(formattedData);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime]);

  React.useEffect(() => {
    pb.collection("edo").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  const update = (createData, id) => {
    pb.collection("edo")
      .update(id, createData)
      .then(() => {
        const updatedData = datetimes.map((row) => {
          if (row.id === id) {
            return { ...row, ...createData };
          }
          return row;
        });
        setDatetimes(updatedData);
      })
      .catch((error) => {
        console.error("Ошибка при обновлении данных:", error);
      });
  };

  const columns = [
    {
      field: "rocket",
      sortable: false,
      maxWidth: 80,
      flex: 1,
      editable: false,
      renderHeader: () => (
        <Grid sx={{ ml: 0.5 }} container justifyContent="center">
          <b>Ссылка</b>
        </Grid>
      ),
      renderCell: (params) => {
        if (params.row.rocketLink !== "") {
          return (
            <Tooltip title={params.row.rocketLink}>
              <Grid sx={{ width: 80 }} container justifyContent="center">
                <Link
                  href={params.row.rocketLink}
                  underline="none"
                  target="_blank"
                >
                  <Button>
                    <Box
                      height={"20px"}
                      component="img"
                      src={rocket}
                      alt="img"
                      sx={{}}
                    ></Box>
                  </Button>
                </Link>
              </Grid>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "created",
      renderHeader: () => {
        return <b>Дата</b>;
      },
      maxWidth: 135,
      flex: 1,
      editable: false,
      valueGetter: (params) => params.row.created.slice(0, 16),
    },
    {
      field: "darkStore",
      renderHeader: () => {
        return <b>Название ЦФЗ</b>;
      },
      maxWidth: 250,
      flex: 1,
      editable: false,
    },
    {
      field: "numberPosta",
      headerName: "УР поставки",
      renderHeader: () => {
        return <b>УР поставки</b>;
      },
      maxWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "nameCompany",
      renderHeader: () => {
        return <b>Поставщик</b>;
      },
      maxWidth: 250,
      flex: 1,
      editable: false,
    },

    {
      field: "status",
      maxWidth: 240,
      flex: 1,
      editable: false,
      renderHeader: () => <b>Шаблон отправки</b>,
      renderCell: (params) => {
        const { row } = params;
        const handleStatusChange = (event, newValue) => {
          row.status = newValue;
        };
        if (params.row.sending === "") {
          return (
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={status}
              value={row.status}
              onChange={handleStatusChange}
              sx={{ zIndex: 100, width: 300 }}
              renderInput={(params) => <TextField {...params} label="Статус" />}
            />
          );
        }
      },
    },
    {
      field: "mail",
      sortable: false,
      maxWidth: 250,
      flex: 1,
      editable: true,
      renderHeader: () => {
        return (
          <Box sx={{}}>
            <b> Почта</b>
          </Box>
        );
      },
    },
    {
      maxWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <b>Отправить</b>,
      renderCell: (params) => {
        const field = params.row.sending;
        const mail = params.row.mail;
    
        if (!mail) {
          return (
            <Grid sx={{ width: 105 }} container justifyContent="center">
              <Tooltip title="Mail field is empty">
                <Button sx={{ ml: 1 }} disabled>
                  <ForwardToInboxIcon />
                </Button>
              </Tooltip>
            </Grid>
          );
        }
    
        if (field !== "") {
          if (field === "true") {
            return (
              <Grid sx={{ width: 100 }} container justifyContent="center">
                <Tooltip title="Письмо отправлено">
                  <MarkEmailReadOutlinedIcon
                    sx={{
                      color: "button.success",
                      width: "64px",
                      height: "36px",
                      p: "6px 8px",
                    }}
                  />
                </Tooltip>
              </Grid>
            );
          } else if (field === "false") {
            return (
              <Grid sx={{ width: 105 }} container justifyContent="center">
                <Tooltip title="Письмо не отправлено">
                  <EmailOutlinedIcon
                    sx={{
                      color: "button.error",
                      p: "6px 8px",
                      width: "64px",
                      height: "36px",
                    }}
                  />
                </Tooltip>
              </Grid>
            );
          }
        } else {
          const handleClick = () => {
            const rowData = params.row;
            const selectedStatus = rowData.status;
            const url = `https://proxy.smkt.pro/push_mail_edo?selectedStatus=${encodeURIComponent(
              selectedStatus
            )}&numberPosta=${encodeURIComponent(
              rowData.numberPosta
            )}&mail=${encodeURIComponent(rowData.mail)}`;
    
            if (selectedStatus !== "") {
              if (selectedStatus !== "Свой текст") {
                if (selectedStatus === "Без письма") {
                  const createData = {
                    status: selectedStatus,
                    users: localStorage.getItem("userId"),
                    sending: "No",
                  };
                  update(createData, rowData.id);
                } else
                  fetch(url)
                    .then(function (response) {
                      return response.text();
                    })
                    .then(function (response_data) {
                      const responseDataObj = JSON.parse(response_data);
                      if (responseDataObj.output.trim() === "Ok") {
                        const createData = {
                          status: selectedStatus,
                          users: localStorage.getItem("userId"),
                          sending: "true",
                        };
                        update(createData, rowData.id);
                      } else {
                        const createData = {
                          status: selectedStatus,
                          users: localStorage.getItem("userId"),
                          sending: "false",
                        };
                        update(createData, rowData.id);
                        setOpen_alert_error(true);
                      }
                    })
                    .catch(function () {
                      const createData = {
                        status: selectedStatus,
                        users: localStorage.getItem("userId"),
                        sending: "false",
                      };
                      update(createData, rowData.id);
                      setOpen_alert_error(true);
                    });
              } else {
                handleOpen();
                setParam(rowData);
              }
            } else {
              console.log("Ошибка: Статус не выбран");
            }
          };
    
          return (
            <Tooltip title="Отправить письмо">
              <Button sx={{ ml: 1 }} onClick={handleClick}>
                <ForwardToInboxIcon />
              </Button>
            </Tooltip>
          );
        }
      },
    },
  ];

  const sendText = () => {
    const url = `https://proxy.smkt.pro/push_mail_edo?selectedStatus=${encodeURIComponent(
      text
    )}&numberPosta=${encodeURIComponent(
      param.numberPosta
    )}&mail=${encodeURIComponent(param.mail)}`;
    fetch(url)
      .then(function (response) {
        return response.text();
      })
      .then(function (response_data) {
        const responseDataObj = JSON.parse(response_data);
        if (responseDataObj.output.trim() === "Ok") {
          const createData = {
            status: text,
            users: localStorage.getItem("userId"),
            sending: "true",
          };
          update(createData, param.id);
        } else {
          const createData = {
            status: text,
            users: localStorage.getItem("userId"),
            sending: "false",
          };
          update(createData, param.id);
        }
      })
      .catch(function (ee) {
        console.log(ee);
        const createData = {
          status: text,
          users: localStorage.getItem("userId"),
          sending: "false",
        };
        update(createData, param.id);
      });
    handleClose();
  };

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject(new Error("Error while saving: name can't be empty."));
            } else {
              resolve({ ...user, name: user.name?.toUpperCase() });
            }
          }, 200);
        }),
      []
    );
  };

  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    async (newRow) => {
      try {
        const response = await mutateRow(newRow);
          await pb.collection("edo").update(newRow.id, newRow);
          const totalItem = await pb.collection('email').getList(1, 50, {filter: `mail = "${newRow.mail}"`})
          console.log(totalItem.totalItems)
          if (totalItem.totalItems === 0) {
            const dataEmail = {
              name: newRow.darkStore,
              mail: newRow.mail,
            };
            await pb.collection("email").create(dataEmail);
          }
        return response;
      } catch (error) {
        console.error("Ошибка при обновлении строки:", error);
        throw error;
      }
    },
    [mutateRow, pb]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {}, []);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 1,
            mb: 1,
            borderRadius: 2,
          }}
        >
          <EDOblack setFirst={setFirst} pb={pb}></EDOblack>
          <Button
            onClick={handleClick}
            sx={{ ml: 2, border: "solid 2.5px rgb(0,150,136)" }}
          >
            {click_button === 0
              ? "Обработанные поставки"
              : "Необработанные поставки"}
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          height: "78vh",
          p: 1,
          bgcolor: "background.paper",
          borderRadius: 2,
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "0px ",
            border: "0px",
            ".MuiDataGrid-cell:focus": { outline: 0 },
            ".MuiDataGrid-cell:focus-within": { outline: 0 },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },

            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          rows={filteredData}
          columns={columns}
          hideFooter={true}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableRowSelectionOnClick
          disableColumnMenu
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      <Dialog fullWidth="true" maxWidth="md" open={open} onClose={handleClose}>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <TextField
              onChange={(e) => setText(e.target.value)}
              sx={{ width: "100%" }}
              id="outlined-multiline-static"
              multiline
              minRows={7}
              maxRows={23}
              defaultValue="Здравствуйте!"
            />
            <Button
              sx={{ width: "40%", border: "solid 2.5px rgb(0,150,136)" }}
              variant="text"
              endIcon={<SendIcon />}
              onClick={sendText}
            >
              Отправить письмо
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={open_alert_error}
        autoHideDuration={4000}
        onClose={handleClose_alert_error}
      >
        <Alert onClose={handleClose_alert_error} severity="error">
          Ошибка отправки
        </Alert>
      </Snackbar>
    </Box>
  );
}
