import * as React from "react";
import Box from "@mui/material/Box";
import readXlsxFile from "read-excel-file";
import LoadingButton from "@mui/lab/LoadingButton";

const token = process.env.REACT_APP_TG_TOKEN_DELETE;

export default function DeleteModal({
  pb,
  setData,
  base,
  baseDelete,
  buttonText,
}) {
  const [chatId, setchatId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    pb.collection(baseDelete)
      .getFullList({
        sort: "-created",
      })
      .then((ait) => setchatId(ait));
  }, [baseDelete]);

  const handleupload = async (e) => {
    setLoading(true);
    const rows = await readXlsxFile(e.target.files[0]);
    for (let i = 1; i < rows.length; i++) {
      const createRows = {
        fullName: rows[i][0],
        tgId: rows[i][1],
      };
      try {
        await deleteTg(rows[i][1], createRows);
      } catch (error) {}
    }
    setLoading(false);
  };

  async function deleteTg(tgId, createRows) {
    const deletePromises = chatId.map((chat) => {
      const payload = {
        method: "banChatMember",
        chat_id: String(chat.chatID),
        user_id: String(tgId),
        parse_mode: "HTML",
      };
      const data = {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(payload),
      };
      return fetch("https://api.telegram.org/bot" + token + "/", data).then(
        (res) => {
          if (res.status >= 200 && res.status < 300) {
            return Promise.resolve();
          } else {
            return Promise.reject(new Error(res.statusText));
          }
        }
      );
    });

    try {
      await Promise.all(deletePromises);
      await pb.collection(base).create(createRows, { $autoCancel: false });
      setData((prev) => [...prev, createRows]);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Box>
      <input
        id="upload-button"
        accept=".xls, .xlsx"
        type="file"
        style={{ display: "none" }}
        value=""
        onChange={handleupload}
      />
      <label htmlFor="upload-button">
        <LoadingButton
          sx={{
            width: "100%",

            height: "56px",
            border: "solid 2.5px rgb(0,150,136)",
          }}
          component="span"
          loading={loading}
        >
          Удалить из {buttonText}
        </LoadingButton>
      </label>
    </Box>
  );
}
