import * as React from "react";
import { Box, Stack } from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import infobip from "../images/infobip.svg";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import servisedesk from "../images/servisedesk.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const text__user_create =
  "Здравствуйте!\nУчетную запись в УзнайPro создали. Попробуйте авторизоваться.\nДанные для входа такие же, как и в приложении Dark Store.";
const text_user_id =
  "Здравствуйте!\nУчетную запись в УзнайPro обновили. Попробуйте авторизоваться.\nДанные для входа такие же, как и в приложении Dark Store.";
const text_user_unlock =
  "Здравствуйте!\nУчетную запись в УзнайPro разблокировали. Попробуйте авторизоваться.\nДанные для входа такие же, как и в приложении Dark Store.";
const text_user_change =
  "Здравствуйте!\nДанные учетной записи УзнайPro изменили. Проверьте, пожалуйста.";
const text_no_content =
  "Здравствуйте!\nКонтент и тренировки прогрузили в учетную запись УзнайPro. Проверьте, пожалуйста.";
const text_contact_administrator =
  "Здравствуйте!\nПришел ответ по вашей заявке по поводу УзнайPro. Обратитесь к администратору  ЦФЗ, чтобы вам предоставили информацию. Если необходимы контакты администратора, свяжитесь с менеджером по подбору.";
const text_additional_information =
  "Здравствуйте!\nСпециалисты запросили у вас дополнительную информацию для решения вопроса в вашей заявке по УзнайPro. Обратитесь к администратору ЦФЗ, чтобы предоставить данные. Если необходимы контакты администратора, свяжитесь с менеджером по подбору.";

export default function DenseTable({ pb, DateTime }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [text, setText] = React.useState("");
  const [data, setData] = React.useState([]);
  const [realtime, setRealtime] = React.useState([]);
  const [param, setParam] = React.useState("");
  const [click_button, setClick_button] = React.useState(0);
  const [open_alert, setOpen_alert] = React.useState(false);
  const [open_alert_error, setOpen_alert_error] = React.useState(false);

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject(new Error("Error while saving: name can't be empty."));
            } else {
              resolve({ ...user, name: user.name?.toUpperCase() });
            }
          }, 200);
        }),
      []
    );
  };
  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    (newRow) => {
      const response = mutateRow(newRow);
      pb.collection("uznaiPRO_sms").update(newRow.id, newRow);
      return response;
    },
    [mutateRow, pb]
  );

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose_alert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen_alert(false);
  };

  const handleClose_alert_error = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen_alert_error(false);
  };

  const handleClick = () => {
    setClick_button((prevValue) => (prevValue === 1 ? 0 : 1));
  };

  function sms_sending(text_sendeing, selected_status, number, id_base) {
    const baseUrl = `https://proxy.smkt.pro/send_sms_uznaipro`;
    const url = `${baseUrl}?number=${number}&text=${encodeURIComponent(
      text_sendeing
    )}`;

    fetch(url)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const responseDataObj = JSON.parse(data);
        console.log(responseDataObj);
        if (responseDataObj.output.status === "OK") {
          const createData = {
            status_sms: selected_status,
            sending_sms: "true",
          };
          update(createData, id_base);
          setOpen_alert(true);
        } else {
          const createData = {
            status_sms: selected_status,
            sending_sms: "false",
          };
          update(createData, id_base);
          setOpen_alert_error(true);
        }
      })
      .catch((error) => {
        console.error(error);
        const createData = {
          status_sms: selected_status,
          sending_sms: "false",
        };
        update(createData, id_base);
        setOpen_alert_error(true);
      });
  }

  const filteredData = data.filter((row) => {
    if (click_button === 0) {
      return row.sending_sms === "";
    } else if (click_button === 1) {
      return row.sending_sms !== "";
    }
    return false; // Return false for other cases
  });

  const status = [
    "Обратитесь к администратору",
    "Дополнительная информация",
    "Если отсутствовал контент",
    "Пользователь с данным id",
    "При изменении данных",
    "Разблокировка",
    "Создание",
    "Свой текст",
    "Без письма",
  ];

  React.useEffect(() => {
    pb.collection("uznaiPRO_sms")
      .getFullList({
        sort: "-created",
      })
      .then((ait) => {
        const formattedData = ait.map((item) => {
          const formattedDate = DateTime.fromFormat(
            item.created,
            "yyyy-MM-dd HH:mm:ss.SSS'Z'"
          )
            .setLocale("ru")
            .plus({ hours: 3 })
            .toFormat("d.MM.yy HH:mm");
          return { ...item, created: formattedDate };
        });

        return setData(formattedData);
      });
  }, [realtime]);

  React.useEffect(() => {
    pb.collection("uznaiPRO_sms").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  const update = (createData, id) => {
    pb.collection("uznaiPRO_sms")
      .update(id, createData)
      .then(() => {
        const updatedData = data.map((row) => {
          if (row.id === id) {
            return { ...row, ...createData };
          }
          return row;
        });
        setData(updatedData);
      })
      .catch((error) => {
        console.error("Ошибка при обновлении данных:", error);
      });
  };

  const columns = [
    {
      field: "link_chats",
      sortable: false,
      maxWidth: 130,
      flex: 1,
      align: "center",
      editable: false,
      renderHeader: () => (
        <Grid sx={{ ml: 0.5 }} container justifyContent="center">
          <b>Ссылка на чат</b>
        </Grid>
      ),
      renderCell: (params) => {
        if (params.row.link_chats !== "") {
          return (
            <Tooltip title={params.row.link_chats}>
              <Grid sx={{ width: 80 }} container justifyContent="center">
                <Link
                  href={params.row.link_chats}
                  underline="none"
                  target="_blank"
                >
                  <Button>
                    <Box
                      height={"30px"}
                      component="img"
                      src={infobip}
                      alt="img"
                      sx={{}}
                    ></Box>
                  </Button>
                </Link>
              </Grid>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "created",
      renderHeader: () => {
        const headerStyle = {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
        return (
          <div style={headerStyle}>
            <b>Дата</b>
          </div>
        );
      },
      maxWidth: 135,
      flex: 1,
      editable: false,
      valueGetter: (params) => params.row.created.slice(0, 16),
    },
    {
      field: "full_name",
      renderHeader: () => {
        return <b>ФИО курьера/сборщика</b>;
      },
      maxWidth: 300,
      flex: 1,
      editable: false,
    },
    {
      field: "number_phone",
      renderHeader: () => {
        return <b>Номер телефона</b>;
      },
      maxWidth: 150,
      flex: 1,
      editable: true,
    },
    {
      field: "link_servisedesk",
      sortable: false,
      maxWidth: 150,
      flex: 1,
      align: "center",
      editable: false,
      renderHeader: () => (
        <Grid sx={{ ml: 0.5 }} container justifyContent="center">
          <b>Ссылка на запрос</b>
        </Grid>
      ),
      renderCell: (params) => {
        if (params.row.link_servisedesk !== "") {
          return (
            <Tooltip title={params.row.link_servisedesk}>
              <Grid
                sx={{ width: 80 }}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Link
                  href={params.row.link_servisedesk}
                  underline="none"
                  target="_blank"
                >
                  <Button>
                    <Box
                      height={"20px"}
                      component="img"
                      src={servisedesk}
                      alt="img"
                      sx={{}}
                    ></Box>
                  </Button>
                </Link>
              </Grid>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "city",
      renderHeader: () => {
        return <b>Город</b>;
      },
      maxWidth: 200,
      flex: 1,
      editable: false,
    },

    {
      field: "status_sms",
      maxWidth: 280,
      flex: 1,
      editable: false,
      renderHeader: () => <b>Шаблон сообщения</b>,
      renderCell: (params) => {
        const { row } = params;
        const handleStatusChange = (event, newValue) => {
          row.status_sms = newValue;
        };
        if (params.row.sending_sms === "") {
          return (
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={status}
              value={row.status_sms}
              onChange={handleStatusChange}
              sx={{ zIndex: 100, width: 300 }}
              renderInput={(params) => <TextField {...params} label="Статус" />}
            />
          );
        }
      },
    },
    {
      maxWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <b>Отправить СМС</b>,
      renderCell: (params) => {
        const field = params.row.sending_sms;
        if (field !== "") {
          if (field === "true") {
            return (
              <Grid sx={{ width: 100 }} container justifyContent="center">
                <Tooltip title="Письмо отправлено">
                  <MarkEmailReadOutlinedIcon
                    sx={{
                      color: "button.success",
                      width: "64px",
                      height: "36px",
                      p: "6px 8px",
                    }}
                  />
                </Tooltip>
              </Grid>
            );
          } else if (field === "false") {
            return (
              <Grid sx={{ width: 105 }} container justifyContent="center">
                <Tooltip title="Письмо не отправлено">
                  <EmailOutlinedIcon
                    sx={{
                      color: "button.error",
                      p: "6px 8px",
                      width: "64px",
                      height: "36px",
                    }}
                  />
                </Tooltip>
              </Grid>
            );
          }
        } else {
          const handleClick = () => {
            const rowData = params.row;
            const selectedStatus = rowData.status_sms;
            if (selectedStatus !== "") {
              if (selectedStatus !== "Свой текст") {
                switch (selectedStatus) {
                  case "Создание":
                    sms_sending(
                      text__user_create,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "Пользователь с данным id":
                    sms_sending(
                      text_user_id,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "Разблокировка":
                    sms_sending(
                      text_user_unlock,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "При изменении данных":
                    sms_sending(
                      text_user_change,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "Если отсутствовал контент":
                    sms_sending(
                      text_no_content,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "Обратитесь к администратору":
                    sms_sending(
                      text_contact_administrator,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "Дополнительная информация":
                    sms_sending(
                      text_additional_information,
                      selectedStatus,
                      rowData.number_phone,
                      rowData.id
                    );
                    break;
                  case "Без письма":
                    const createData = {
                      status_sms: selectedStatus,
                      sending_sms: "No",
                    };
                    update(createData, rowData.id);
                    break;
                }
              } else {
                handleOpen();
                setParam(rowData);
              }
            } else {
              console.log("Ошибка: Статус не выбран");
            }
          };
          return (
            <Tooltip title="Отправить письмо">
              <Button sx={{ ml: 1 }} onClick={handleClick}>
                <ForwardToInboxIcon />
              </Button>
            </Tooltip>
          );
        }
      },
    },
  ];

  const sendText = () => {
    sms_sending(text, text, param.number_phone, param.id);
    handleClose();
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 1,
            mb: 1,
            borderRadius: 2,
          }}
        >
          <Button
            onClick={handleClick}
            sx={{ border: "solid 2.5px rgb(0,150,136)" }}
          >
            {click_button === 0
              ? "Обработанные заявки"
              : "Необработанные заявки"}
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          height: "78vh",
          p: 1,
          bgcolor: "background.paper",
          borderRadius: 2,
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "0px ",
            border: "0px",
            ".MuiDataGrid-cell:focus": { outline: 0 },
            ".MuiDataGrid-cell:focus-within": { outline: 0 },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },

            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          rows={filteredData}
          columns={columns}
          hideFooter={true}
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          disableColumnMenu
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      <Dialog fullWidth="true" maxWidth="md" open={open} onClose={handleClose}>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <TextField
              onChange={(e) => setText(e.target.value)}
              sx={{ width: "100%" }}
              id="outlined-multiline-static"
              multiline
              minRows={7}
              maxRows={23}
              defaultValue="Здравствуйте!"
            />
            <Button
              sx={{ width: "40%", border: "solid 2.5px rgb(0,150,136)" }}
              variant="text"
              endIcon={<SendIcon />}
              onClick={sendText}
            >
              Отправить письмо
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={open_alert}
        autoHideDuration={4000}
        onClose={handleClose_alert}
      >
        <Alert onClose={handleClose_alert} severity="success">
          Сообщение отправлено
        </Alert>
      </Snackbar>
      <Snackbar
        open={open_alert_error}
        autoHideDuration={4000}
        onClose={handleClose_alert_error}
      >
        <Alert onClose={handleClose_alert_error} severity="error">
          Ошибка отправки
        </Alert>
      </Snackbar>
    </Box>
  );
}
