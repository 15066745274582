import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tg_bot_ruk_lyam_reporting from "./Tg_bot_ruk_lyam_reporting";

export default function Tg_bot_ruk_lyam_create({ pb, data }) {
  const [supervisor, setSupervisor] = useState("");
  const [name_student, setName_student] = useState("");
  const [tg_id, setTg_id] = useState("");
  const [tg_name, setTg_name] = useState("");
  const [tg_login, setTg_login] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      if (value.length <= 10) {
        let formatted = value.replace(/\D/g, "");

        if (formatted.length >= 2 && formatted.length <= 4) {
          formatted = formatted.replace(/(\d{2})(\d{0,2}).*/, "$1.$2");
        }

        if (formatted.length >= 5 && formatted.length <= 7) {
          formatted = formatted.replace(
            /(\d{2})(\d{2})(\d{0,4}).*/,
            "$1.$2.$3"
          );
        }

        if (formatted.length >= 8) {
          formatted = formatted.replace(/(\d{2})(\d{2})(\d{4}).*/, "$1.$2.$3");
        }

        setFormattedDate(formatted);
      }
    }
  };

  const placeholder = isFocused ? "DD.MM.YYYY" : "";

  const name_supervisor = [
    ...new Set(
      data.map((person) => {
        return person.fullname_supervisor;
      })
    ),
  ];

  function createRows() {
    if (formattedDate.length === 10) {
      pb.collection("tg_bot_ruk_lyam")
        .create({
          tg_id: tg_id,
          tg_login: tg_login,
          fullname_supervisor: supervisor,
          tg_name: tg_name,
          name_student: name_student,
          data_student: formattedDate,
        })
        .then();
    }
  }

  return (
    <Stack
      component="form"
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        bgcolor: "background.paper",
        p: 2,
        borderRadius: "8px",
        minWidth: 560,
      }}
    >
      <Stack
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={21}
      >
        <Tg_bot_ruk_lyam_reporting data={data} />

        <Typography sx={{ color: "text.secondary" }} variant="h6" gutterBottom>
          Добавить ученика
        </Typography>
      </Stack>
      <Autocomplete
        disablePortal
        sx={{ width: "100%" }}
        id="combo-box-demo"
        options={name_supervisor}
        getOptionLabel={(option) => `${option}`}
        value={supervisor}
        onChange={(event, newInputValue) => {
          setSupervisor(newInputValue);
          const selectedPerson = data.find(
            (person) => person.fullname_supervisor === newInputValue
          );
          if (selectedPerson) {
            setTg_id(selectedPerson.tg_id);
            setTg_name(selectedPerson.tg_name);
            setTg_login(selectedPerson.tg_login);
          }
        }}
        onInputChange={(e, value) => setSupervisor(value)}
        renderInput={(params) => (
          <TextField
            required
            label="Руководитель"
            variant="outlined"
            {...params}
          />
        )}
      />
      <TextField
        autoComplete="off"
        required
        onChange={(e) => setName_student(e.target.value)}
        id="outlined-basic"
        label="ФИО ученика"
        variant="outlined"
        value={name_student}
        sx={{ width: "100%" }}
      />
      <TextField
        required
        autoComplete="off"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id="outlined-basic"
        label="Дата выхода"
        variant="outlined"
        value={formattedDate}
        placeholder={placeholder}
        sx={{ width: "100%" }}
      />
      <Button
        type="submit"
        sx={{ width: "100%", border: "solid 2.5px rgb(0,150,136)" }}
        onClick={createRows}
        endIcon={<AddIcon />}
      >
        Добавить
      </Button>
    </Stack>
  );
}
