import * as React from "react";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import SearchToolbar from "../../../../shared/SearchToolbar.jsx";
import { pb } from "../../../../shared/pb.js";
import useApi from "../../api/useApi.js";

export default function RoutingTable({ tableBlock }) {
  const { data } = useApi();
  const columns = [
    {
      valueParser: (value) => {
        return value.toUpperCase();
      },
      field: "tag",
      renderHeader: () => {
        return <b>ТЕГ</b>;
      },

      width: 80,
      editable: tableBlock,
    },
    {
      valueParser: (value) => {
        return value.toUpperCase();
      },
      field: "name",
      renderHeader: () => {
        return <b>Название DarkStore</b>;
      },

      width: 270,
      editable: tableBlock,
    },
    {
      field: "koordinator",
      renderHeader: () => {
        return <b>Координатор</b>;
      },
      width: 230,
      editable: tableBlock,
    },
    {
      field: "time",
      renderHeader: () => {
        return <b>Время работы (МСК)</b>;
      },
      width: 170,
      editable: false,
    },
  ];

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject(new Error("Error while saving: name can't be empty."));
            } else {
              resolve({ ...user, name: user.name?.toUpperCase() });
            }
          }, 200);
        }),
      []
    );
  };

  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    (newRow) => {
      const response = mutateRow(newRow);
      pb.collection("routing").update(newRow.id, newRow);
      return response;
    },
    [mutateRow, pb]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {}, []);

  return (
    <Box
      sx={{
        height: "87vh",
        p: 2,
        minWidth: { md: 800, sm: 800 },
        bgcolor: "background.paper",
        borderRadius: "8px",
      }}
    >

      <DataGrid
        sx={{
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          border: "0px",
          ".MuiDataGrid-cell:focus": { outline: 0 },
          ".MuiDataGrid-cell:focus-within": { outline: 0 },
          ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },
          overflowX: "auto",
        }}
        rows={data}
        columns={columns}
        disableColumnMenu
        slots={{
          toolbar: SearchToolbar,
        }}
        stickyHeader
        disableRowSelectionOnClick
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}
