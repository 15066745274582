import { Button } from "@mui/material";
import * as XLSX from "xlsx";

export default function Tg_bot_ruk_lyam_reporting({ data }) {
  function reporting() {
    const rows = [];
    rows.push([
      "Руководитель",
      "Новичок",
      "Дата выхода",
      "Новичок получил велком-бокс?",
      "Дал новичку обратную связь?",
      "Новичок прошёл вводный курс «Добро пожаловать в Самокат»?",
      "Запланировал ли ты встречу с новичком по итогам ИС?",
      "Новичок прошел обучающий цикл по ценностям компании?",
      "Почему новичок не планирует проходить вводный курс «Добро пожаловать в Самокат»?",
      "Почему новичок не планирует проходить обучающий цикл по ценностям компании",
    ]);

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const record = data[key];
        const row = [
          record.fullname_supervisor,
          record.name_student,
          record.data_student,
          record.sms_1,
          record.sms_2,
          record.sms_3,
          record.sms_4,
          record.sms_5,
          record.sms_5_reason,
          record.sms_8_reason,
        ];
        rows.push(row);
      }
    }

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Лист1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAs(excelBuffer, "Tg_bot_ruk_lyam.xlsx");
  }

  function saveAs(buffer, fileName) {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const anchor = document.createElement("a");
      if (anchor.download !== undefined) {
        const url = URL.createObjectURL(blob);
        anchor.setAttribute("href", url);
        anchor.setAttribute("download", fileName);
        anchor.style.visibility = "hidden";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    }
  }

  return <Button onClick={reporting}>Скачать отчетность</Button>;
}
