import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "./Rating";
import Outsourc from "./Outsourc";
import Divider from "@mui/material/Divider";

export default function Outsourcing({ pb, DateTime }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          bgcolor: "background.paper",
          p: 8,
          borderRadius: "8px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Rating DateTime={DateTime} pb={pb}></Rating>
        </Box>
        <Divider orientation="vertical" sx={{ mr: 8, ml: 8 }} />
        <Box sx={{ p: 2 }}>
          <Outsourc DateTime={DateTime} pb={pb}></Outsourc>
        </Box>
      </Box>
    </Box>
  );
}
