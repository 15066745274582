import CreateDarkstore from "./modules/createDarkStore/CreateDarkstore";
import RoutingTable from "./modules/routingTable/RoutingTable";
import Grid from "@mui/material/Grid";

const СoordinatorRoutingPage = ({ setFirst, tableBlock }) => {
  return (
    <Grid spacing={2} justifyContent="center" alignItems="center" container>
      <Grid item>
        {localStorage.getItem("role") === process.env.REACT_APP_ADMIN_ROLE ||
        localStorage.getItem("role") ===
          process.env.REACT_APP_HELP_EDIT_ROLE ? (
          <CreateDarkstore></CreateDarkstore>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item>
        <RoutingTable
          setFirst={setFirst}
          tableBlock={tableBlock}
        ></RoutingTable>
      </Grid>
    </Grid>
  );
};

export default СoordinatorRoutingPage;
