import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import { Divider, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TelegramIcon from "@mui/icons-material/Telegram";

export default function DeleteChats({ pb, base }) {
  const [data, setData] = React.useState([]);
  const [chatID, setChatID] = React.useState("");
  const [nameChats, setNameChats] = React.useState("");
  const [realtime, setRealtime] = React.useState();

  React.useEffect(() => {
    pb.collection(base)
      .getFullList({
        sort: "-created",
      })
      .then((ait) => setData(ait));
  }, [base, realtime]);

  const createData = {
    chatID: chatID,
    chatName: nameChats,
  };

  function create() {
    pb.collection(base)
      .create(createData)
      .then((Ans) => {
        Promise.all([
          setData((prev) => [Ans, ...prev]),
          setNameChats(""),
          setChatID(""),
        ]);
      });
  }

  React.useEffect(() => {
    pb.collection(base).subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, [base]);

  const columns = [
    {
      editable: false,
      sortable: false,

      renderCell: () => <TelegramIcon sx={{ color: "text.secondary" }} />,
    },
    {
      field: "chatName",
      renderHeader: () => (
        <Grid sx={{ width: 250 }} container justifyContent="center">
          Название чата
        </Grid>
      ),
      width: 475,
      editable: false,
      sortable: false,

      renderCell: (params) => (
        <Grid container sx={{ pl: 5 }}>
          {params.value}
        </Grid>
      ),
    },

    {
      field: "chatID",
      renderHeader: () => (
        <Grid sx={{}} container justifyContent="center">
          Сhat ID
        </Grid>
      ),
      renderCell: (params) => (
        <Grid sx={{ pr: 8 }} container justifyContent="center">
          {params.value}
        </Grid>
      ),
      width: 180,
      disableColumnMenu: true,
      editable: false,
      sortable: false,
    },
  ];

  return (
    <Box
      sx={{
        height: "68vh",
        width: 900,
        bgcolor: "background.paper",
      }}
    >
      <DataGrid
        sx={{
          borderRadius: "0px ",
          border: "0px",
          ".MuiDataGrid-cell:focus": { outline: 0 },
          ".MuiDataGrid-cell:focus-within": { outline: 0 },
          ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
        }}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        columns={columns}
        hideFooter={true}
        pageSize={5}
        stickyHeader
        disableColumnSelector
        rowsPerPageOptions={[5]}
        disableRowSelectionOnClick
      />
      <Divider></Divider>
      <Stack
        sx={{
          width: 900,
          bgcolor: "background.paper",
          p: 1,
          borderRadius: "0px 0px 8px 8px",
        }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          sx={{ width: "45%" }}
          onChange={(e) => setNameChats(e.target.value)}
          id="outlined-basic-name"
          label="Название чата"
          variant="outlined"
          value={nameChats} // Установка значения из состояния
        />
        <TextField
          sx={{ width: "45%", ml: 1 }}
          onChange={(e) => setChatID(e.target.value)}
          id="outlined-basic-id"
          label="Чат ID"
          variant="outlined"
          value={chatID} // Установка значения из состояния
        />
        <Button
          sx={{
            width: "10%",
            border: "solid 2px rgb(0,150,136)",
            height: "56px",
            ml: 1,
          }}
          onClick={create}
        >
          <AddIcon />
        </Button>
      </Stack>
    </Box>
  );
}
