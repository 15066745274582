import * as React from "react";
import { Box } from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import SBL_create from "./SBL_create";
import Tooltip from "@mui/material/Tooltip";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

const columns = [
  {
    maxWidth: 50,
    editable: false,
    sortable: false,

    renderCell: () => <TelegramIcon sx={{ color: "text.secondary" }} />,
  },
  {
    field: "created",
    renderHeader: () => {
      return <b>Дата</b>;
    },
    maxWidth: 135,
    flex: 1,
    editable: false,
    valueGetter: (params) => params.value,
  },

  {
    field: "rp",
    renderHeader: () => {
      return <b>RP посылки</b>;
    },
    maxWidth: 150,
    flex: 1,
    editable: false,
  },
  {
    field: "statusRP",
    renderHeader: () => {
      return <b>Статус посылки</b>;
    },
    maxWidth: 220,
    flex: 1,
    editable: false,
  },
  {
    field: "darkStore",
    renderHeader: () => {
      return <b>ЦФЗ посылки</b>;
    },
    maxWidth: 300,
    flex: 1,
    editable: false,
  },

  {
    field: "note",
    renderHeader: () => {
      return <b>Примечание</b>;
    },
    minWidth: 200,
    flex: 1,
    editable: false,
  },
  {
    field: "sms",
    maxWidth: 80,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => null,
    renderCell: (params) => {
      const field = params.row.sms;

      return field === "true" ? (
        <Tooltip title="Сообщение отправлно">
          <MarkEmailReadOutlinedIcon
            sx={{
              color: "button.success",
              width: "64px",
              height: "36px",
              p: "6px 8px",
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Ошибка отправки">
          <EmailOutlinedIcon
            sx={{
              color: "button.error",
              p: "6px 8px",
              width: "64px",
              height: "36px",
            }}
          />
        </Tooltip>
      );
    },
  },
];

export default function DenseTable({ setFirst, pb, DateTime }) {
  const [datetimes, setDatetimes] = React.useState([]);
  const [realtime, setRealtime] = React.useState();

  datetimes.forEach(function (record) {});

  React.useEffect(() => {
    pb.collection("sbl_tg_bot")
      .getFullList({
        sort: "-created",
      })
      .then((ait) => {
        const formattedData = ait.map((item) => {
          const formattedDate = DateTime.fromFormat(
            item.created,
            "yyyy-MM-dd HH:mm:ss.SSS'Z'"
          )
            .setLocale("ru")
            .plus({ hours: 3 })
            .toFormat("d.MM.yy HH:mm");
          return { ...item, created: formattedDate };
        });

        setDatetimes(formattedData);
      });
  }, [pb, realtime]);

  React.useEffect(() => {
    pb.collection("sbl_tg_bot").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  return (
    <Box sx={{}}>
      <SBL_create
        datetimes={datetimes}
        pb={pb}
        setDatetimes={setDatetimes}
      ></SBL_create>
      <Box
        sx={{
          height: "78vh",
          p: 2,
          bgcolor: "background.paper",
          borderRadius: "8px",
          mt: 1,
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "0px ",
            border: "0px",
            ".MuiDataGrid-cell:focus": { outline: 0 },
            ".MuiDataGrid-cell:focus-within": { outline: 0 },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },
            "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
              {
                display: "none",
              },

            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          rows={datetimes}
          columns={columns}
          hideFooter={true}
          disableRowSelectionOnClick
          disableColumnMenu
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Box>
  );
}
