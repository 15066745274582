import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function BasicCard({ pb }) {
  const [data, setData] = React.useState([]);
  const [realtime, setRealtime] = React.useState();
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    pb.collection("statisticsBot")
      .getFullList({
        sort: "-created",
        $autoCancel: false,
      })
      .then((ait) => setData(ait));
  }, [realtime]);

  React.useEffect(() => {
    pb.collection("statisticsBot").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);


  const handleSwitchChange = async (id, currentStatus) => {
    const updatedStatus = !currentStatus;
    await pb.collection("statisticsBot").update(id, { status: updatedStatus });
    setRealtime(updatedStatus); // This will trigger the data refresh
  };

  const columns = [
    {
      field: "email",
      headerName: "Почта",
      width: 250,
      editable: true,
    },
    {
      field: "tg_id",
      headerName: "Телеграм ИД",
      width: 210,
      editable: false,
      sortable: false,
    },
    {
      field: "tg_login",
      headerName: "Телеграм логин",
      width: 205,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Статус",
      width: 100,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={() => handleSwitchChange(params.row.id, params.row.status)}
          />
        );
      },
    },
  ];

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject(new Error("Error while saving: name can't be empty."));
            } else {
              resolve({ ...user, name: user.name });
            }
          }, 200);
        }),
      []
    );
  };

  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    (newRow) => {
      const response = mutateRow(newRow);
      pb.collection("statisticsBot").update(newRow.id, newRow);
      return response;
    },
    [mutateRow, pb]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {}, []);

  const createData = {
    email: email,
    // chatName: nameChats,
  };

  function create() {
    pb.collection("statisticsBot")
      .create(createData)
      .then((Ans) => {
        setEmail("")
      });
  }
  
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
        <Box>
        <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        bgcolor: "background.paper",
        p: 1,
        borderRadius: "8px",
        width: "800px",
      }}
    >
      <TextField
        onChange={(e) => setEmail(e.target.value)}
        id="outlined-basic"
        label="Почта"
        variant="outlined"
        value={email}
        multiline
        sx={{ width: "100%" }}
      />
      <Button
        sx={{
          width: "30%",
          minWidth: "140px",
          border: "solid 2.5px rgb(0,150,136)",
          height: "56px",
          p: 1,
        }}
        onClick={() => create()}
      >
        Добавить
      </Button>
    </Stack>
        </Box>
         <Box
        sx={{
          height: "82vh",
          p: 2,
          minWidth: "800px",
          bgcolor: "background.paper",
          borderRadius: "8px",
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "0px ",
            border: "0px",
            ".MuiDataGrid-cell:focus": { outline: 0 },
            ".MuiDataGrid-cell:focus-within": { outline: 0 },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },

            "& .MuiDataGrid-columnHeader:nth-of-type(1) .MuiDataGrid-columnSeparator":
              {
                display: "none",
              },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          rows={data}
          columns={columns}
          disableColumnMenu
          stickyHeader
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Stack>
  );
}
