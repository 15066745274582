import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import Box from "@mui/material/Box";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList({ pb }) {
  const [data, setData] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [filterData, setFilterData] = React.useState(data);
  const [filterData2, setFilterData2] = React.useState(data2);
  const [checked, setChecked] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const [datarealtime, Setdatarealtime] = React.useState([]);
  const leftChecked = intersection(
    checked,
    filterData.map((item) => item.name)
  );
  const rightChecked = intersection(
    checked,
    filterData2.map((item) => item.name)
  );

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    pb.collection("blackList").subscribe("*", (e) => {
      Setdatarealtime(e.record);
    });
  }, []);

  React.useEffect(() => {
    pb.collection("blackList")
      .getFullList({
        sort: "-created",
      })
      .then((ait) => {
        const res = [];
        const res1 = [];
        ait.map((item) => {
          if (item.field) {
            res.push(item);
            setData(res);
          } else {
            res1.push(item);
            setData2(res1);
          }
        });
      });
  }, [datarealtime]);

  React.useEffect(() => {
    setFilterData(data);
    setFilterData2(data2);
  }, [data, data2]);

  const update = (selected) => {
    selected.map((item) =>
      pb
        .collection("blackList")
        .update(item.id, { ...item, field: !item.field })
    );
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    intersection(
      checked,
      items.map((item) => item.name)
    ).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(
        not(
          checked,
          items.map((item) => item.name)
        )
      );
    } else {
      setChecked(
        union(
          checked,
          items.map((item) => item.name)
        )
      );
    }
  };

  const handleCheckedRight = () => {
    if (leftChecked.length === 0) {
      return;
    }

    const selectedData = leftChecked.map((value) => {
      return filterData.find((item) => item.name === value);
    });

    setData2((prevData2) => prevData2.concat(selectedData));
    setData((prevData) => not(prevData, selectedData));
    setChecked(not(checked, leftChecked));
    update(selectedData);
  };

  const handleCheckedLeft = () => {
    if (rightChecked.length === 0) {
      return;
    }

    const selectedData = rightChecked.map((value) => {
      return filterData2.find((item) => item.name === value);
    });

    setData((prevData) => prevData.concat(selectedData));
    setData2((prevData2) => not(prevData2, selectedData));
    setChecked(not(checked, rightChecked));
    update(selectedData);
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} Выбрано`}
      />
      <Divider />
      <List
        sx={{
          width: 350,
          height: 550,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value.name)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.name) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const handleNoMatchesButtonClick = () => {
    const data = {
      name: searchValue,
      field: true,
    };
    pb.collection("blackList").create(data);
  };

  return (
    <React.Fragment>
      {/* <Box
        sx={{
          bgcolor: "background.paper",
          p: 1,
          mb: 1,
          borderRadius: 2,
          width: "13%",
        }}
      > */}
      <Button
        onClick={handleClickOpen}
        sx={{ border: "solid 2.5px rgb(0,150,136)" }}
        endIcon={<DomainDisabledIcon />}
      >
        Black List
      </Button>
      {/* </Box> */}

      <Dialog fullWidth="true" maxWidth="md" open={open} onClose={handleClose}>
        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            <Stack sx={{ pb: 1 }}>
              <TextField
                id="standard-basic"
                label="Поиск"
                variant="outlined"
                sx={{ width: "800px" }}
                value={searchValue}
                onChange={(e) => {
                  const searchText = e.target.value.toLowerCase();
                  const filteredData = data.filter((item) =>
                    item.name.toLowerCase().includes(searchText)
                  );
                  const filteredData2 = data2.filter((item) =>
                    item.name.toLowerCase().includes(searchText)
                  );
                  setFilterData(filteredData);
                  setFilterData2(filteredData2);
                  setSearchValue(e.target.value);
                }}
              />
              {filterData.length === 0 && filterData2.length === 0 && (
                <Button
                  sx={{ top: 3 }}
                  variant="outlined"
                  color="primary"
                  onClick={handleNoMatchesButtonClick}
                >
                  Добавить
                </Button>
              )}
            </Stack>

            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid sx={{}} item>
                {customList("Партнер", filterData)}
              </Grid>
              <Grid sx={{}} item>
                <Stack direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={checked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={checked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Stack>
              </Grid>
              <Grid sx={{}} item>
                {customList("Недопустимый", filterData2)}
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
