import React from "react";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";
import "./App.css";
import Marshrut from "./components/Marshrut";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const [mode, setMode] = useState(
    localStorage.getItem("mode") ? localStorage.getItem("mode") : "dark"
  );

  const theme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          mobile: 400,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      palette: {
        mode: mode,
        ...(mode === "light"
          ? 
          {
              primary: {
                light: "#008080",

                main: "#009688",

                contrastText: "#fff",
                text: "#fff",
              },
              secondary: {
                main: "#009688",

                contrastText: "#fff",
              },
              background: {
                default: "#f2f2f2",
                paper: "#ffffff",
              },
              action: {
                active: "#595959",
              },
              text: {
                primary: "#202020",
                secondary: "#595959",
                disabled: "#a6a6a6",
              },
              button: { success: "#4caf50", error: "#ef5350" },
            }
          : {
              primary: {
                light: "#008080",

                main: "#009688",
                text: "#000000",
              },
              background: {
                paper: "#272626",
              },
              secondary: {
                main: "#009688",

                contrastText: "#fff",
              },
              button: {
                success: "#1b5e20",
                error: "#c62828",
              },
            }),
      },
    },
    ruRU
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Marshrut setMode={setMode} mode={mode} theme={theme} />
    </ThemeProvider>
  );
}

export default App;
