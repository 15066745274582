import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import logoTeal from "../images/logoTeal.svg";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import LoginIcon from "@mui/icons-material/Login";

export default function Authorization({ pb, setFirst, setMode, mode, theme }) {
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // const handleToggleMode = () => {
  //   const newMode = mode === "light" ? "dark" : "light";
  //   setMode(newMode);
  //   localStorage.setItem("mode", newMode);
  // };

  function auth(login, password) {
    pb.collection("users")
      .authWithPassword(login, password)
      .then((data) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.record.role);
        localStorage.setItem("userId", data.record.id);
        setFirst((prev) => !prev);
      });
  }

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        height: "100vh",
      }}
      noValidate
      autoComplete="off"
    >
      {/* <IconButton
        sx={{ position: "absolute", top: 10, right: 10 }}
        onClick={handleToggleMode}
        color="inherit"
      >
        {theme.palette.mode === "dark" ? <WbSunnyIcon /> : <Brightness3Icon />}
      </IconButton> */}
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
        sx={{ bgcolor: "background.paper", p: 17, borderRadius: "8px" }}
      >
        <Box
          component="img"
          src={logoTeal}
          alt="img"
          sx={{
            mb: "10px",
            height: "60px",
          }}
        ></Box>
        <OutlinedInput
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          type="text"
          placeholder="Логин"
          sx={{
            width: "300px",
            borderRadius: "8px",
            mt: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <OutlinedInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Пароль"
          type={showPassword ? "text" : "password"}
          sx={{
            width: "300px",
            borderRadius: "8px",
            mt: "20px",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          sx={{
            width: "100%",
            border: "solid 2.5px #009688",
            borderRadius: "8px",
          }}
          onClick={() => {
            auth(login, password);
          }}
          endIcon={<LoginIcon />}
        >
          Вход
        </Button>{" "}
      </Stack>
    </Box>
  );
}
