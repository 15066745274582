import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function SBL_create({ datetimes, pb, setDatetimes }) {
  const [statusRP, setStatusRP] = React.useState("");
  const [rp, setRp] = React.useState("");
  const [darkStore, setDarkStore] = React.useState("");
  const [note, setNote] = React.useState("");
  const chatID = process.env.REACT_APP_TG_GROUP_SBL;
  const API = process.env.REACT_APP_TG_TOKEN_SBL;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [successOpen, setSuccessOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const countStatusRP = [
    { label: "Не принято" },
    { label: "Ожидает доставки" },
    { label: "Доставляется" },
    { label: "Доставлено" },
    { label: "Ожидает возврата" },
    { label: "Возвращено" },
    { label: "Потеряна" },
  ];

  const SMStg = () => {
    const message =
      "<code>RP посылки:</code> " +
      rp +
      "\n" +
      "<code>Статус посылки:</code> " +
      statusRP +
      "\n" +
      "<code>ЦФЗ посылки:</code> " +
      darkStore +
      "\n" +
      "<code>Примечание:</code> " +
      note;
    send(message, chatID);
  };

  const createRowsOk = {
    rp: rp,
    darkStore: darkStore,
    note: note,
    statusRP: statusRP,
    sms: "true",
  };

  const createRowsError = {
    rp: rp,
    darkStore: darkStore,
    note: note,
    statusRP: statusRP,
    sms: "false",
  };

  const send = (msg, chat_id) => {
    const payload = {
      method: "sendMessage",
      chat_id: String(chat_id),
      text: msg,
      parse_mode: "HTML",
    };

    axios
      .post(`https://api.telegram.org/bot${API}/`, payload)
      .then((response) => {
        pb.collection("sbl_tg_bot")
          .create(createRowsOk, { $autoCancel: false })
          .then((Ans) => setDatetimes((prev) => [Ans, ...prev]));

        setStatusRP("");
        setRp("");
        setDarkStore("");
        setNote("");
        setSuccessOpen(true);
      })
      .catch((error) => {
        pb.collection("sbl_tg_bot")
          .create(createRowsError, { $autoCancel: false })
          .then((Ans) => setDatetimes((prev) => [Ans, ...prev]));

        setStatusRP("");
        setRp("");
        setDarkStore("");
        setNote("");
        setErrorOpen(true);
      });
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        bgcolor: "background.paper",
        p: 1,
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <TextField
        onChange={(e) => setRp(e.target.value)}
        id="outlined-basic"
        label="RP посылки"
        variant="outlined"
        value={rp}
        multiline
        maxRows={3}
        sx={{ minWidth: "15%" }}
      />

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        sx={{ minWidth: "15%" }}
        options={countStatusRP}
        inputValue={statusRP}
        multiline
        onInputChange={(event, newInputValue) => {
          setStatusRP(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Статус посылки" />
        )}
      />

      <TextField
        onChange={(e) => setDarkStore(e.target.value)}
        id="outlined-basic"
        label="ЦФЗ посылки"
        variant="outlined"
        value={darkStore}
        multiline
        maxRows={3}
        sx={{ minWidth: "15%" }}
      />

      <TextField
        onChange={(e) => setNote(e.target.value)}
        id="outlined-basic"
        label="Примечание"
        variant="outlined"
        value={note}
        multiline
        maxRows={3}
        sx={{ minWidth: "40%" }}
      />

      <Button
        sx={{
          width: "100%",
          minWidth: "140px",
          border: "solid 2.5px rgb(0,150,136)",
          height: "56px",
          p: 1,
        }}
        onClick={() => SMStg()}
        endIcon={<ForwardToInboxIcon />}
      >
        Отправить
      </Button>
      <Snackbar open={successOpen} autoHideDuration={6000}>
        <Alert severity="success">Сообщение успешно отправлено</Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={6000}>
        <Alert open={errorOpen} autoHideDuration={6000} severity="error">
          Ошибка отправки
        </Alert>
      </Snackbar>
    </Stack>
  );
}
