import React, { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PersonIcon from "@mui/icons-material/Person";
import Tg_bot_ruk_lyam_create from "./Tg_bot_ruk_lyam_create";

export default function Tg_bot_ruk_lyam({ pb, DateTime }) {
  const [data, setData] = React.useState([]);
  const [realtime, setRealtime] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedItemSupervisor, setSelectedItemSupervisor] =
    React.useState(null);
  const [name_collums, setname_collums] = React.useState("name_student");
  const [filterData, setFilterData] = React.useState(data);
  const [status, setStatus] = React.useState("0");
  const [sum_name, setSum_name] = React.useState([]);
  const [filter_rows, setFilter_rows] = React.useState(filterData);

  React.useEffect(() => {
    if (status === "1") {
      const uniqueSupervisors = filterData.reduce((acc, item) => {
        const existingItem = acc.find(
          (obj) => obj.fullname_supervisor === item.fullname_supervisor
        );
        if (!existingItem) {
          acc.push(item);
        }
        return acc;
      }, []);
      setFilter_rows(uniqueSupervisors);
    } else {
      // Here, we are adding an additional condition to filter out rows with empty name_student
      setFilter_rows(
        filterData.filter((item) =>
          status === "0" ? item.name_student.trim() !== "" : true
        )
      );
    }
  }, [status, realtime, data]);

  React.useEffect(() => {
    pb.collection("tg_bot_ruk_lyam")
      .getFullList({
        sort: "-created",
      })
      .then((ait) => {
        setData(ait);
        setFilterData(
          ait.filter((item) =>
            item.name_student.toLowerCase().includes(searchValue)
          )
        );
      });
  }, [realtime]);

  React.useEffect(() => {
    pb.collection("tg_bot_ruk_lyam").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredData = data.filter((item) =>
      item.name_student.toLowerCase().includes(searchText)
    );
    setFilterData(filteredData);
    setSearchValue(e.target.value);
  };

  const handleRowClick = (params) => {
    if (status === "0") {
      setSelectedItem(params.row);
      setSelectedItemSupervisor(null);
    } else {
      const clickedFormatId = params.row.fullname_supervisor;

      const array = data
        .filter((item) => item.fullname_supervisor === clickedFormatId)
        .map((item) => item.name_student);
      setSum_name(array);
      setSelectedItemSupervisor(params.row);
      setSelectedItem(null);
    }
  };

  React.useEffect(() => {
    if (selectedItemSupervisor) {
      const children = sum_name.map((name) => (
        <Typography key={name}>{name}</Typography>
      ));

      setSelectedItemSupervisor((prevSelectedItemSupervisor) => ({
        ...prevSelectedItemSupervisor,
        children: (
          <Fragment>
            <Typography>Ученики:</Typography>

            {children}
          </Fragment>
        ),
      }));
    }
  }, [sum_name]);

  const columns = [
    {
      valueParser: (value) => {
        return value;
      },
      field: name_collums,
      renderHeader: () => {
        return (
          <TextField
            id="standard-basic"
            label="Поиск по имени"
            variant="standard"
            sx={{ width: 560 }}
            value={searchValue}
            onChange={handleSearchChange}
          />
        );
      },
      width: 560,
      editable: true,
      sortable: false,
      editable: false,
    },
  ];

  return (
    <Stack direction="row" justifyContent="center" spacing={5}>
      <Box
        sx={{
          height: "86vh",
          p: 2,
          minWidth: "600px",
          bgcolor: "background.paper",
          borderRadius: "8px",
        }}
      >
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="Новые сотрудники"
            icon={<PersonIcon />}
            sx={{
              minWidth: "250px",
            }}
            onClick={() => {
              setname_collums("name_student");
              setStatus("0");
            }}
          />

          <BottomNavigationAction
            label="Руководители"
            icon={<PersonIcon />}
            sx={{
              minWidth: "250px",
            }}
            onClick={() => {
              setname_collums("fullname_supervisor");
              setStatus("1");
            }}
          />
        </BottomNavigation>

        <DataGrid
          sx={{
            height: "76vh",
            border: "0px",
            ".MuiDataGrid-cell:focus": { outline: 0 },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "0px",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "0px",
            },
          }}
          rows={filter_rows}
          columns={columns}
          disableColumnMenu
          stickyHeader
          hideFooter={true}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
        />
      </Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Box></Box>
        <Tg_bot_ruk_lyam_create pb={pb} data={data}></Tg_bot_ruk_lyam_create>

        {selectedItemSupervisor && (
          <Card
            sx={{
              width: 560,
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {selectedItemSupervisor.fullname_supervisor}
              </Typography>
              {selectedItemSupervisor.children}
            </CardContent>
          </Card>
        )}

        {selectedItem && (
          <Card
            sx={{
              width: 560,
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {selectedItem.name_student}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Руководитель:
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: "bold", fontSize: 13 }}
              >
                {selectedItem.fullname_supervisor}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Новичок получил велком бокс?
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: "bold", fontSize: 13 }}
              >
                {selectedItem.sms_1 !== ""
                  ? selectedItem.sms_1
                  : "Нет информации"}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Руководитель дал новичку обратную связь?
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: "bold", fontSize: 13 }}
              >
                {selectedItem.sms_2 !== ""
                  ? selectedItem.sms_2
                  : "Нет информации"}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Новичок прошёл вводный курс?
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: "bold", fontSize: 13 }}
              >
                {selectedItem.sms_3 !== ""
                  ? selectedItem.sms_3
                  : "Нет информации"}
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 13,
                    wordWrap: "break-word",
                  }}
                >
                  {selectedItem.sms_5_reason}
                </Typography>
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Руководитель запланировал встречу по итогам ИС?
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: "bold", fontSize: 13 }}
              >
                {selectedItem.sms_4 !== ""
                  ? selectedItem.sms_4
                  : "Нет информации"}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Новичок прошёл обучающий цикл по ценностям компании?
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: "bold", fontSize: 13 }}
              >
                {selectedItem.sms_5 !== ""
                  ? selectedItem.sms_5
                  : "Нет информации"}
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 13,
                    wordWrap: "break-word",
                  }}
                >
                  {selectedItem.sms_8_reason}
                </Typography>
                <Divider />
              </Typography>

              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                Зарегистрирован:ㅤ
                {DateTime.fromFormat(
                  selectedItem.created,
                  "yyyy-MM-dd HH:mm:ss.SSS'Z'"
                )
                  .setLocale("ru")
                  .plus({ hours: 3 })
                  .toLocaleString(DateTime.DATETIME_SHORT)}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Stack>
    </Stack>
  );
}
