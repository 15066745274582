import * as React from "react";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import CreateSV from "./CreateSV";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import TelegramIcon from "@mui/icons-material/Telegram";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import SearchToolbar from "../shared/SearchToolbar.jsx";

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === "") {
            reject(new Error("Error while saving: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name });
          }
        }, 200);
      }),
    []
  );
};
<Link sx={{ color: "#616161" }} underline="none" target="_blank">
  <Typography sx={{ color: "#616161" }} variant="body2">
    Ссылка на группу
  </Typography>
</Link>;

export default function GroupsSV({ pb, setFirst }) {
  const [data, setData] = React.useState([]);
  const [realtime, setRealtime] = React.useState([]);

  React.useEffect(() => {
    pb.collection("groupsSV")
      .getFullList({
        sort: "-created",
      })
      .then((ait) => setData(ait));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime]);

  React.useEffect(() => {
    pb.collection("groupsSV").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  const columns = [
    {
      editable: false,
      sortable: false,

      renderCell: () => <TelegramIcon sx={{ color: "text.secondary" }} />,
    },
    {
      valueParser: (value) => {
        return value;
      },
      field: "name",
      renderHeader: () => {
        return <b>Название группы</b>;
      },
      width: 250,
      editable: true,
    },
    {
      valueParser: (value) => {
        return value;
      },
      field: "link",
      renderHeader: () => {
        return <b>Ссылка на группу</b>;
      },
      width: 300,
      editable: true,
      sortable: false,
    },
    {
      field: "delete",
      minWidth: 70,
      flex: 1,
      sortable: false,
      disableColumnMenu: false,
      renderHeader: () => null,
      renderCell: (params) => {
        const handleDelete = () => {
          const { id } = params.row;
          pb.collection("groupsSV")
            .delete(id)
            .then(() => {
              const updatedData = data.filter((row) => row.id !== id);
              setData(updatedData);
            });
        };
        return (
          <Tooltip title="Удалить">
            <IconButton
              sx={{ ml: 5, color: "text.secondary" }}
              onClick={handleDelete}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    (newRow) => {
      const response = mutateRow(newRow);
      pb.collection("groupsSV").update(newRow.id, newRow);
      return response;
    },
    [mutateRow, pb]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {}, []);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <CreateSV pb={pb} setData={setData}></CreateSV>

      <Box
        sx={{
          height: "87vh",
          p: 2,
          minWidth: "800px",
          bgcolor: "background.paper",
          borderRadius: "8px",
        }}
      >
        <DataGrid
          sx={{
            borderRadius: "0px ",
            border: "0px",
            ".MuiDataGrid-cell:focus": { outline: 0 },
            ".MuiDataGrid-cell:focus-within": { outline: 0 },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },

            "& .MuiDataGrid-columnHeader:nth-of-type(1) .MuiDataGrid-columnSeparator":
              {
                display: "none",
              },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          slots={{
            toolbar: SearchToolbar,
          }}
          rows={data}
          columns={columns}
          disableColumnMenu
          stickyHeader
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Stack>
  );
}
