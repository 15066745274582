import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CardActionArea } from "@mui/material";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DateRangeIcon from "@mui/icons-material/DateRange";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Rating({ pb, DateTime }) {
  const [allData, setAllData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [realtime, setRealtime] = React.useState();

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    pb.collection("pythonBotRating")
      .getList(1, 5, {
        sort: "-created",
      })
      .then((value) => {
        setAllData(value.items);
      });
  }, [open, realtime]);

  React.useEffect(() => {
    pb.collection("pythonBotRating").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleupload = async (e) => {
    const formData = new FormData();
    formData.append("documents", e.target.files[0]);
    setLoading(true);
    try {
      await pb.collection("pythonBotRating").create(formData);
    } catch (error) {
      console.log(error);
    }
    setOpen(true);
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      fetch(`${process.env.REACT_APP_URL_GO_SCRIPTS}/upload`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Request sent successfully.");
        })
        .catch((error) => {
          console.error("Error sending request:", error);
        });
    }
  }, [open]);

  return (
    <Box>
      <Box>
        <Box>
          <Card
            sx={{
              boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
            }}
          >
            <CardActionArea onChange={handleupload}>
              <label htmlFor="fileRating">
                <CardContent>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <input
                      style={{ display: "none" }}
                      accept=".xls, .xlsx"
                      type="file"
                      id="fileRating"
                      value=""
                    />

                    {loading === true ? (
                      <LoadingButton
                        sx={{ height: "50px", width: "50px" }}
                        loading
                        variant="text"
                      />
                    ) : (
                      <StarHalfIcon
                        sx={{ height: "50px", width: "50px" }}
                      ></StarHalfIcon>
                    )}
                    <Typography variant="h5">
                      {loading === true ? "Загрузка файла..." : "Добавить файл"}
                    </Typography>

                    <Typography sx={{ color: "text.secondary" }} variant="h6">
                      {loading === true
                        ? "Не закрывайте вкладку"
                        : "Рейтинг курьеров"}
                    </Typography>

                    <Typography
                      sx={{ color: "text.secondary" }}
                      variant="h6"
                    ></Typography>
                  </Stack>
                </CardContent>
              </label>
            </CardActionArea>
          </Card>
        </Box>
        <List
          sx={{
            mt: 2,
          }}
        >
          <ListItem>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>

            <ListItemText primary="Последние обновления" />
          </ListItem>
          <Box sx={{ minHeight: "180px" }}>
            {allData.map((data) => (
              <Box key={data.id} sx={{ pt: 0.5 }}>
                <ListItemText
                  sx={{
                    color: "text.secondary",

                    textAlign: "center",
                  }}
                  primary={DateTime.fromFormat(
                    data.created,
                    "yyyy-MM-dd HH:mm:ss.SSS'Z'"
                  )
                    .setLocale("ru")
                    .plus({ hours: 3 })
                    .toLocaleString(DateTime.DATETIME_SHORT)}
                />
                <Divider />
              </Box>
            ))}
          </Box>
        </List>
        <Box>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%", mb: "30px" }}
            >
              Файл успешно загружен
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
}
