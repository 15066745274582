import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CardActionArea } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Outsourcing({ pb, DateTime }) {
  const [allData, setAllData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [realtime, setRealtime] = React.useState();

  React.useEffect(() => {
    pb.collection("pythonBotOutsourcing")
      .getList(1, 5, {
        sort: "-created",
      })
      .then((value) => {
        setAllData(value.items);
      });
  }, [open, realtime]);

  React.useEffect(() => {
    pb.collection("pythonBotOutsourcing").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleuploadOutsourcing = async (e) => {
    const formData = new FormData();
    formData.append("documents", e.target.files[0]);
    setLoading(true);
    try {
      const createdRecord = await pb
        .collection("pythonBotOutsourcing")
        .create(formData);
    } catch (error) {
      console.log(error);
    }
    setOpen(true);
    setLoading(false);
  };

  React.useEffect(() => {
    if (open) {
      fetch(`${process.env.REACT_APP_URL_GO_SCRIPTS}/upload_user_company`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Request sent successfully.");
        })
        .catch((error) => {
          console.error("Error sending request:", error);
        });
    }
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Box>
      <Box>
        <Box>
          <Card
            sx={{
              boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
            }}
          >
            <CardActionArea>
              <label htmlFor="fileOutsourcing">
                <CardContent>
                  <input
                    onChange={handleuploadOutsourcing}
                    style={{ display: "none" }}
                    accept=".xls, .xlsx"
                    type="file"
                    id="fileOutsourcing"
                    value=""
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading === true ? (
                      <LoadingButton
                        sx={{ height: "50px", width: "50px" }}
                        loading
                        variant="text"
                      />
                    ) : (
                      <ApartmentIcon
                        sx={{ height: "50px", width: "50px" }}
                      ></ApartmentIcon>
                    )}

                    <Typography variant="h5">
                      {loading === true ? "Загрузка файла..." : "Добавить файл"}
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }} variant="h6">
                      {loading === true
                        ? "Не закрывайте вкладку"
                        : "Компания аутсорс"}
                    </Typography>
                  </Stack>
                </CardContent>
              </label>
            </CardActionArea>
          </Card>
        </Box>
        <List
          sx={{
            mt: 2,
            bgcolor: "background.paper",
          }}
        >
          <ListItem>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>

            <ListItemText primary="Последние обновления" />
          </ListItem>
          <Box sx={{ minHeight: "180px" }}>
            {allData.map((data) => (
              <Box key={data.id} sx={{ pt: 0.5 }}>
                <ListItemText
                  sx={{
                    color: "text.secondary",

                    textAlign: "center",
                  }}
                  key={data.id}
                  primary={DateTime.fromFormat(
                    data.created,
                    "yyyy-MM-dd HH:mm:ss.SSS'Z'"
                  )
                    .setLocale("ru")
                    .plus({ hours: 3 })
                    .toLocaleString(DateTime.DATETIME_SHORT)}
                />
                <Divider />
              </Box>
            ))}
          </Box>
        </List>
      </Box>
      <Box>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%", mb: "30px" }}
          >
            Файл успешно загружен
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
