import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Stack,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";

export default function ClosingChats({ pb }) {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [tagsData, setTagsData] = useState([]);
  const [data_base, setData_base] = React.useState([]);
  const [realtime, setRealtime] = React.useState();
  const [record_base, setRecord_base] = React.useState(0);
  const [defaultText, setDefaultText] = useState(
    "Здравствуйте!\n\nИзвините, что не смогли ответить вовремя. За выходные получили огромное количество обращений. Если вам всё ещё нужна помощь — напишите, пожалуйста, повторно. Обязательно во всём разберёмся 🙏\n\nЕсли вопрос уже неактуален, то оставьте это сообщение без ответа. Желаем прекрасного дня и продуктивной недели"
  );
  const [errors, setErrors] = useState({
    queue: false,
    tags: false,
    agent: false,
  });
  const url_backend = process.env.REACT_APP_URL_BACKEND;

  React.useEffect(() => {
    pb.collection("close_chat")
      .getFullList({
        sort: "-created",
      })
      .then((ait) => {
        setData_base(ait);
        if (ait[0] && ait[0].status === true) {
          setActiveStep(1);
          setRecord_base(ait[0].items / (ait[0].total_items / 100));
        } else {
          setActiveStep(0);
        }
      });
  }, [realtime]);

  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };
    pb.collection("close_chat").subscribe("*", (e) => {
      setRealtime(e.record);
    });
    // fetchData(`${url_backend}/get_queue`, setQueueData);
    fetchData(`${url_backend}/get_tags`, setTagsData);
  }, []);

  const handleButtonClick = async () => {
    const newErrors = {
      queue: !selectedQueue || !selectedQueue["QueueID"],
      tags: !selectedTags || !selectedTags["tags"],
    };
    setErrors(newErrors);
    // if (!newErrors.queue && !newErrors.tags) {
      // if (activeStep === 0) {
      //   try {
      //     const response = await fetch(
      //       `${url_backend}/get_chats?status=OPEN&queueIds=${selectedQueue["QueueID"]}`
      //     );
      //     const data = await response.json();
      //     setGetChats(data);
      //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //   } catch (error) {
      //     console.error("Ошибка при выполнении запроса:", error);
      //   }
      // } else 
      if (activeStep === 0) {
        const url_close = `${url_backend}/close_chats`;
        const data_close = {
          Text: defaultText,
          // QueueID: selectedQueue["QueueID"],
          Tags: selectedTags["tags"],
          UserIDbase: localStorage.getItem("userId"),
        };
        fetch(url_close, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data_close),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
            if (data["status"] == "ok") {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      // }
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      // sx={{ marginTop: "50px" }}
    >
      <Stepper activeStep={activeStep} orientation="horizontal">
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          bgcolor: "background.paper",
          p: 3,
          width: "500px",
          height: "480px",
        }}
      >
        {activeStep === 0 && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {/* <Autocomplete
              disablePortal
              id="queue-combo-box"
              options={queueData}
              getOptionLabel={(option) => option["QueueName"]}
              onChange={(event, newValue) => setSelectedQueue(newValue)}
              renderInput={(params) => (
                <div style={{ position: "relative" }}>
                  <TextField
                    {...params}
                    label="Выбери очередь"
                    sx={{ width: 450 }}
                    error={errors.queue}
                  />
                  {errors.queue && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: "absolute", marginLeft: "10px" }}
                    >
                      Пожалуйста, заполните это поле.
                    </Typography>
                  )}
                </div>
              )}
            /> */}
            <Autocomplete
              disablePortal
              id="tags-combo-box"
              options={tagsData}
              getOptionLabel={(option) => option["tags"]}
              onChange={(event, newValue) => setSelectedTags(newValue)}
              renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Выбери тэг"
                    sx={{ width: 450 }}
                  />
              )}
            />

            <TextField
              id="outlined-multiline-static"
              label="Текст для отправки"
              multiline
              sx={{ width: 450 }}
              rows={12}
              value={defaultText}
              onChange={(e) => setDefaultText(e.target.value)}
            />
            <Button
              disabled = {!selectedTags}
              sx={{ width: 450 }}
              variant="contained"
              onClick={handleButtonClick}
            >
              Запустить автозакрытие
            </Button>
          </Stack>
        )}
        {activeStep === 1 && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Box>
              {`Закрыто ${data_base[0].items} чатов из ${data_base[0].total_items}`}
              <LinearProgress variant="determinate" value={record_base} />
            </Box>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}
