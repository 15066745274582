import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function BasicTextFields({ setData, pb }) {
  const [name, setName] = React.useState("");
  const [link, setLink] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const createData = {
    name: name,
    link: link,
  };

  function createRows() {
    pb.collection("groupsSV")
      .create(createData)
      .then((Ans) => setData((prev) => [Ans, ...prev]));
    setName("");
    setLink("");
    setOpen(true);
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        bgcolor: "background.paper",
        p: 2,
        borderRadius: "8px",
      }}
    >
      <Typography sx={{ color: "text.secondary" }} variant="h5" gutterBottom>
        Добавить группу СВ
      </Typography>
      <TextField
        autoComplete="off"
        onChange={(e) => setName(e.target.value)}
        id="outlined-basic"
        label="Название группы"
        variant="outlined"
        value={name}
        sx={{ width: 330 }}
      />
      <TextField
        onChange={(e) => setLink(e.target.value)}
        autoComplete="off"
        id="outlined-basic"
        label="Ссылка на группу"
        variant="outlined"
        value={link}
        sx={{ width: 330 }}
      />
      <Button
        sx={{ width: "100%", border: "solid 2.5px rgb(0,150,136)" }}
        onClick={createRows}
        endIcon={<AddIcon />}
      >
        Добавить
      </Button>{" "}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Группа СВ добавлена
        </Alert>
      </Snackbar>
    </Stack>
  );
}
