import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import uznaiPRO_white from "../images/uznaiPRO_white.svg";
import uznaiPRO_black from "../images/uznaiPRO_black.svg";

export default function UznaiPRO_forma({ pb }) {
  const [link_chats, setLink_chats] = React.useState("");
  const [full_name, setFull_name] = React.useState("");
  const [number_phone, setNumber_phone] = React.useState("");
  const [link_servisedesk, setLink_servisedesk] = React.useState("");
  const [city, setCity] = React.useState("");

  function createRows(createData) {
    pb.collection("uznaiPRO_sms").create(createData);
    setLink_chats("");
    setFull_name("");
    setNumber_phone("");
    setLink_servisedesk("");
    setCity("");
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box
        sx={{
          bgcolor: "background.paper",
          width: 900,
          p: 6,
          borderRadius: 2,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
        >
          <Stack
            direction="row-reverse"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Box
              height={"35px"}
              component="img"
              src={
                localStorage.getItem("mode") === "dark"
                  ? uznaiPRO_white
                  : uznaiPRO_black
              }
              alt="img"
              sx={{}}
            ></Box>
            <Typography
              sx={{ color: "text.secondary" }}
              variant="h5"
              gutterBottom
            >
              Обратная связь
            </Typography>
          </Stack>
          <TextField
            autoComplete="off"
            onChange={(e) => setFull_name(e.target.value)}
            id="outlined-basic"
            variant="standard"
            label="ФИО курьера/сборщика"
            value={full_name}
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <TextField
            autoComplete="off"
            onChange={(e) => setCity(e.target.value)}
            id="outlined-basic"
            variant="standard"
            label="Город"
            value={city}
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <TextField
            autoComplete="off"
            onChange={(e) => setNumber_phone(e.target.value)}
            id="outlined-basic"
            variant="standard"
            label="Номер телефона"
            value={number_phone}
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <TextField
            autoComplete="off"
            onChange={(e) => setLink_chats(e.target.value)}
            id="outlined-basic"
            variant="standard"
            value={link_chats}
            label="Ссылка на чат"
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <TextField
            autoComplete="off"
            onChange={(e) => setLink_servisedesk(e.target.value)}
            id="outlined-basic"
            variant="standard"
            value={link_servisedesk}
            label="Ссылка на запрос в servicedesk"
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <Button
            sx={{ width: "40%", border: "solid 2.5px rgb(0,150,136)" }}
            onClick={() =>
              createRows({
                full_name: full_name,
                city: city,
                number_phone: number_phone,
                link_chats: link_chats,
                link_servisedesk: link_servisedesk,
              })
            }
            endIcon={<AddIcon />}
          >
            Добавить
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
