import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function BasicCard({ pb }) {
  const [data, setData] = React.useState([]);
  const [numberPosta, setNumberPosta] = React.useState("");
  const [nameCompany, setNameCompany] = React.useState("");
  const [rocketLink, setRocketLink] = React.useState("");
  const [darkStore, setDarkStore] = React.useState(null);
  const [mail, setMail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [addDarkStore, setaddDarkStore] = React.useState("");
  const [addEmail, setaddEmail] = React.useState("");
  const [test, setTest] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [realtime, setRealtime] = React.useState();
  console.log(addDarkStore);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  React.useEffect(() => {
    pb.collection("email")
      .getFullList({
        sort: "name",
      })
      .then((ait) => {
        const uniqueValuesMap = new Map();

        ait.map(function (obj) {
          const key = obj.name;
          if (!uniqueValuesMap.has(key)) {
            uniqueValuesMap.set(key, obj);
          }
        });
        const uniqueValuesArray = Array.from(uniqueValuesMap.values());
        setData(uniqueValuesArray);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [test, realtime]);

  React.useEffect(() => {
    pb.collection("email").subscribe("*", (e) => {
      setRealtime(e.record);
    });
  }, []);

  // const FilterDarkStore = [
  //   ...new Set(
  //     data.map((person) => {
  //       return person.name;
  //     })
  //   ),
  // ];

  const add = {
    name: addDarkStore,
    mail: addEmail,
  };

  function createDarkStore() {
    pb.collection("email")
      .create(add)
      .then((ait) => {
        setTest((prev) => !prev);
        setDarkStore(ait);
        setMail(ait.mail);
      });

    setOpen(false);
    setaddDarkStore("");
    setaddEmail("");
  }

  function createRows(createData) {
    pb.collection("edo")
      .create(createData)
      .then((Ans) => {
        setData((prev) => [Ans, ...prev]);
      });
    setNumberPosta("");
    setNameCompany("");
    setRocketLink("");
    setDarkStore(null);
    setMail("");
    setaddDarkStore(null);
    setOpenSnackbar(true);
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box
        sx={{
          bgcolor: "background.paper",
          width: 900,
          p: 6,
          borderRadius: 2,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
        >
          <Typography
            sx={{ color: "text.secondary" }}
            variant="h5"
            gutterBottom
          >
            Обратная связь
          </Typography>
          <Box sx={{ width: "100%", minHeight: "100px" }}>
            <Box>
              <Autocomplete
                noOptionsText={
                  <Button onClick={() => setOpen(true)}>
                    Добавить DarkStore
                  </Button>
                }
                disablePortal
                id="combo-box-demo"
                options={data}
                getOptionLabel={(option) => `${option.name}`}
                value={darkStore}
                onChange={(event, newInputValue, reason) => {
                  setDarkStore(newInputValue);
                  setMail(newInputValue?.mail);
                }}
                // onInputChange={(e, value) => setaddDarkStore(e)} // Сохряняет значение ЦФЗ в модалку
                // onChange={(event, newValue) => {
                //   if (newValue) {
                //     const selectedData = data.find(
                //       (person) => person.name === newValue
                //     );
                //     if (selectedData) {
                //       setMail(selectedData.mail);
                //     }
                //   }
                // }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    label="Название ЦФЗ"
                    variant="standard"
                    {...params}
                  />
                )}
              />

              <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
                {mail}
              </Typography>
            </Box>
          </Box>
          <TextField
            autoComplete="off"
            onChange={(e) => setNumberPosta(e.target.value)}
            id="outlined-basic"
            variant="standard"
            label="УР поставки"
            value={numberPosta}
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <TextField
            autoComplete="off"
            onChange={(e) => setNameCompany(e.target.value)}
            id="outlined-basic"
            variant="standard"
            label="Поставщик"
            value={nameCompany}
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <TextField
            autoComplete="off"
            onChange={(e) => setRocketLink(e.target.value)}
            id="outlined-basic"
            variant="standard"
            value={rocketLink}
            label="Ссылка запроса"
            sx={{ width: "100%", minHeight: "100px" }}
          />
          <Button
            sx={{ width: "40%", border: "solid 2.5px rgb(0,150,136)" }}
            onClick={() =>
              createRows({
                darkStore: darkStore?.name,
                mail: mail,
                numberPosta: numberPosta,
                nameCompany: nameCompany,
                rocketLink: rocketLink,
              })
            }
            endIcon={<AddIcon />}
          >
            Добавить
          </Button>
        </Stack>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogContent>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography
                sx={{ color: "text.secondary" }}
                variant="h5"
                gutterBottom
              >
                Добавить ЦФЗ
              </Typography>
              <TextField
                onChange={(e) => setaddDarkStore(e.target.value)}
                id="outlined-basic"
                variant="standard"
                label="Название ЦФЗ"
                defaultValue={addDarkStore}
                sx={{ width: "90%" }}
              />
              <TextField
                onChange={(e) => setaddEmail(e.target.value)}
                id="outlined-basic"
                variant="standard"
                defaultValue={addEmail}
                label="Email ЦФЗ"
                sx={{ width: "90%" }}
              />
              <Button
                sx={{ width: "40%", border: "solid 2.5px rgb(0,150,136)" }}
                onClick={createDarkStore}
                endIcon={<AddIcon />}
              >
                Сохранить
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            ЦФЗ Добавлено
          </Alert>
        </Snackbar>
      </Box>
    </Stack>
  );
}
